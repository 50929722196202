import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getBookings, getBookingsV2, getCalendarDataV2 } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components'
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";

import Typography from "@mui/material/Typography";
import { colorArray, formatMobileNumber } from '../../../utils/Commonfunction';
import { Select, Tooltip, Box, Divider } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import ToggleTabs from '../../../ReuseableComponets/ToggleTab';
import { ArrowBackIosNew, ArrowForwardIos, CalendarMonth } from '@mui/icons-material';
import mapStyles from '../../Tracking/mapStyles';
import { useRef } from 'react';
import { useEffect } from 'react';
import DatePickerComponent from '../../../ReuseableComponets/DatePickerComponent';
import UnScheduledJobs from "../../../Pages/unScheduledJobs";
import { Player } from '@lottiefiles/react-lottie-player';
import { useState } from 'react';


function MapView() {
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const localizer = momentLocalizer(moment)
    const [loader, setLoader] = React.useState([])

    const [AllData, setAllData] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [currentDate, setCurrentDate] = useState(new Date());
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Map")
    const [type, setType] = useState("Scheduled")
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipId, setTooltipId] = useState('');
    const [activeMarker, setActiveMarker] = useState(null);
    const [tabData, setTabData] = useState([])
    const [value, setValue] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorPosition, setAnchorPosition] = useState(null);
    const mapRef = useRef(null);


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;


    const navigate = useNavigate();
    const startDate = (date) => {
        const start = moment(date).subtract(2, 'days').format('YYYY-MM-DD'); 
        const end = moment(date).add(2, 'day').format('YYYY-MM-DD'); 

        return { start, end }; 
    };
    const fetchLocationData =async (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const { start, end } = startDate(date);
        const res1 = await dispatch(getCalendarDataV2(start,end));
       
        console.log(res1?.data?.detailData,formattedDate,"ddddddwwwwdddd")
        const dataForDate = res1?.data?.detailData?.filter((item) =>{
            const newDate = moment(item?.date).format('YYYY-MM-DD');
                console.log(newDate,formattedDate,newDate===formattedDate)
                if(newDate===formattedDate){
                    return item
                }
        });
        console.log(dataForDate,"dddddd")
        const promises =
        await Promise.all(
            dataForDate?.map((item, index) => {
                if (item?.latitude && item?.longitude) {
                    return {
                        name: item?.customerAddress,
                        position: { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                        data: item
                    };
                }
                return null;
            })
        )
        const filteredData = promises?.filter(item => item !== null);
        // const promises = dataForDate.map((item, index) => {
        //     return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${item?.customerAddress}&key=AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA`)
        //         .then(response => response.json())
        //         .then(data => {
        //             if (data?.results && data?.results?.length > 0) {
        //                 console.log('location', data.results[0].geometry.location);
        //                 const location = data.results[0].geometry.location;
        //                 const jitteredLatitude = location.lat + (index * 0.0001);
        //                 const jitteredLongitude = location.lng + (index * 0.0001);
        //                 return {
        //                     name: item?.customerAddress,
        //                     position: { lat: jitteredLatitude, lng: jitteredLongitude },
        //                     data: item
        //                 };
        //             } else {
        //                 console.warn(`No results found for address: ${item?.customerAddress}`);
        //                 return {
        //                     name: item?.customerAddress,
        //                     position: { lat: 0, lng: 0 },
        //                     data: item
        //                 };
        //             }
        //         });
        // });
       return filteredData
  
    };

    const handlePrevDate = () => {
        setLoader(true)
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() - 1);
            fetchLocationData(newDate)
            .then((arrData) => {
                console.log(arrData, "Previous date data");
                setTabData(arrData);
            })
            .catch((error) => {
                console.error("Error fetching geocode data for previous date:", error);
            });
            return newDate;
        });
        setLoader(false)
    };

    const handleNextDate = () => {
        setLoader(true)
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() + 1);
            fetchLocationData(newDate)
            .then((arrData) => {
                console.log(arrData, "Previous date data");
                setTabData(arrData);
            })
            .catch((error) => {
                console.error("Error fetching geocode data for previous date:", error);
            });
        
            return newDate;
        });
        setLoader(false)

    };
  

    const init = async () => {
        setLoader(true)
        const { start, end } = startDate(new Date());
        const res1 = await dispatch(getCalendarDataV2(start,end));
        setAllData([...res1?.data?.detailData])
        if (res1.status === true) {
            const data = [...res1?.data?.detailData]?.filter(items => items?.customerAddress);
            const data1 = data?.filter((item) => moment(item?.date)?.format('DD MM YYY') === moment(new Date())?.format('DD MM YYY'))
            const promises =
            await Promise.all(
                data1?.map((item, index) => {
                    if (item?.latitude && item?.longitude) {
                        return {
                            name: item?.customerAddress,
                            position: { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                            data: item
                        };
                    }
                    return null;
                })
            )
            const filteredData = promises?.filter(item => item !== null);
            // const promises = data1.map((item, index) => {

            //     return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${item?.customerAddress}&key=AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA`)
            //         .then(response => response.json())
            //         .then(data => {
            //             if (data?.results && data?.results?.length > 0) {
            //                 console.log('location', data.results[0].geometry.location)
            //                 const location = data.results[0].geometry.location;
            //                 const jitteredLatitude = location.lat + (index * 0.0001);
            //                 const jitteredLongitude = location.lng + (index * 0.0001);
            //                 return {
            //                     name: item?.customerAddress,
            //                     position: { lat: jitteredLatitude, lng: jitteredLongitude },
            //                     data: item
            //                 };
            //             }
            //             else {
            //                 console.warn(`No results found for address: ${item?.customerAddress}`);
            //                 return {
            //                     name: item?.customerAddress,
            //                     position: { lat: 0, lng: 0 },
            //                     data: item
            //                 }
            //             }
            //         });

            // });
       setTabData(filteredData)
        }
        setLoader(false)

    };
    React.useEffect(() => {
        init()
    }, [])
    const handleSelectEvent = (event) => {
        setValue({})
        // setIsModal(true)
        // setSelectedEvent(event?.data);
        navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
    };


    const eventStyleGetter = (event, start, color, end, isSelected) => {
        console.log(color, event)
        const style = {
            backgroundColor: event?.color ? event?.color : palette.newBlue,
            borderRadius: '2px',
            padding: '4px',
            opacity: 0.8,
            color: 'white',
            fontSize: '12px',
            border: '0px solid #fff',
            opacity: 1.0,
            display: 'block',
            marginLeft: '5px',
            overflowY: 'hidden',
            marginLeft: '3px',

        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {

        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            navigate('/dashboard/calander?type=week')
        };

        const goToDay = () => {
            navigate('/dashboard/calander?type=day')
        };
        const goToMonth = () => {
            navigate('/dashboard/calander?type=month')
        };



        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginTop: '-10px', marginLeft: '10px' }}>
                        <ToggleTabs
                            labels={['Scheduled', 'Unscheduled']}
                            selected={type}
                            onToggle={(val) => {

                                if (val === 'Scheduled') {
                                    setType('Scheduled')
                                }

                                else {
                                    setType('Unscheduled')
                                }
                            }}

                        />
                    </div>
                    <div className="rbc-toolbar" >

                        <div style={{ display: 'flex', marginLeft: '20px' }} >

                            <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => handlePrevDate()}>
                                <ArrowBackIosNew style={{ fontSize: '18px' }} />
                            </button>
                            {/* <span style={{ alignSelf: 'center', fontSize: '14px',fontWeight:'500' }} className="rbc-toolbar-label">{toolbar.label}</span> */}
                            <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => handleNextDate()}>
                                <ArrowForwardIos style={{ fontSize: '18px' }} />
                            </button>
                            {/* <span style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '500' }} className="rbc-toolbar-label">
                            <CalendarMonth />
                            <div style={{ marginLeft: '8px' }}>{moment(currentDate).format('DD MMM YYYY')}</div>
                        </span> */}

                            <DatePickerComponent
                                selectedDate={currentDate}
                                onDateChange={(text) => {
                                    setCurrentDate(text)
                                    fetchLocationData(text)
                                        .then((arrData) => {
                                            
                                            setTabData(arrData);
                                        })
                                        .catch((error) => {
                                            console.error("Error fetching geocode data for previous date:", error);
                                        });
                                }}
                                iconStyle={{ fontSize: '20px', marginTop: '-2px' }}
                                customStyle={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40px', fontWeight: '550', fontSize: '14px', border: 'none' }}
                            />



                        </div>

                        <span className="rbc-toolbar-label"></span>
                        {/* <span style={{ marginRight: '20px' }} className="rbc-btn-group">
                    <Selectbox
                        style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                        placeholder='select' value={'Select an option'} displayEmpty
                        renderValue={() => filter} onChange={(e) => {
                            console.log(e.target)

                        }}
                        MenuProps={{ // Add MenuProps to customize menu styling
                            PaperProps: {
                                style: {
                                    marginTop: '8px', // Adjust this value for the desired gap
                                },
                            },
                        }}
                    >
                        <StyledMenuItem type="button" onClick={() => {
                            setFilter('Day')

                        }}>
                            Day
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToWeek}>
                            Week
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToMonth}>
                            Month
                        </StyledMenuItem>

                        {/* <StyledMenuItem type="button" onClick={goToAgendaView}>
                        Agenda
                    </StyledMenuItem> */}

                    </div>
                </div>

                <div style={{ marginBottom: '15px', marginRight: '15px' }}>
                    <ToggleTabs
                        labels={['Week', 'Month', "Day", "Map"]}
                        selected={filter}
                        onToggle={(val) => {

                            if (val === 'Week') {
                                goToWeek()
                            }
                            else if (val === 'Month') {
                                goToMonth()
                            }
                            else if (val === 'Day') {
                                setFilter('Day')
                                navigate('/dashboard/timeline')
                            }
                            else {
                                setFilter('Map')
                            }
                        }}

                    />
                </div>
            </div>
        );
    };

    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD'); // Extract the day number
        const dayName = moment(label).format('ddd'); // Extract the day name


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
 


    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {
                backgroundColor: isToday && 'transparent'// Set the background color to transparent for today's date

            },
        };
    }


    const handleSelectSlot = (e) => {
        console.log(e)

        setValue({
            date: e?.start?.toISOString(),
            endDate: e?.end?.toISOString(),
            time: e?.start?.toISOString(),
            endTime: e?.end?.toISOString(),
            edit: true

        })
        setIsModal(true)

        // setIsModal(true)
        // `start` and `end` contain the selected range of dates

        // You can perform any actions with the selected dates here
    };
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);



    const handleDateChange = (newDate, view) => {
        console.log("New date:", newDate);
        console.log("View:", view); // The current view (e.g., 'week', 'day', etc.)
        // You can add your custom logic here
    };
    const handleOnLoad = (map) => {
        mapRef.current = map;
        fitBoundsToMarkers(map);


    };

    const fitBoundsToMarkers = (map) => {
       

        const bounds = new window.google.maps.LatLngBounds();

        tabData.forEach(({ position }) => {
            bounds.extend(new window.google.maps.LatLng(position.lat, position.lng));
        });

        map.fitBounds(bounds);
        if (tabData.length > 1) {
            // Fit bounds if there are multiple markers
            map.fitBounds(bounds);

            // Maintain a minimum zoom level (e.g., 4) after fitting bounds
            const listener = map.addListener('bounds_changed', () => {
                const currentZoom = map.getZoom();
                if (currentZoom > 8) {
                    map.setZoom(8); // Set zoom level to 4 if it exceeds 4
                }
                window.google.maps.event.removeListener(listener);
            });
        } else {
            // If there's only one marker, set zoom to 8
            map.setCenter(bounds.getCenter());
            map.setZoom(8);
        }

    };
    useEffect(() => {
        if (mapRef.current && tabData && tabData.length > 0) {
            fitBoundsToMarkers(mapRef.current);
        }
    }, [tabData]);

    return (
        <Body>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '-1px', height: '100vh', paddingTop: '15px', }}>

                <CalanderTime
                    localizer={localizer}
                    events={[]}
                    resources={[]}


                    defaultDate={new Date()}
                    style={{ width: '100%' }}

                    step={60}
                    timeslots={1}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    dayPropGetter={dayPropStyles}
                    onNavigate={handleDateChange}
                    views={false} // Disable all views
                    selectable={false} // Disable selection

                    defaultView={'day'}
                    eventPropGetter={eventStyleGetter}
                    formats={{

                        eventTimeRangeFormat: () => {
                            return ""
                        }





                    }}
                    components={{
                        toolbar: MyToolbar,
                        event: () => null,


                        day: {
                            header: dayssHeaderContent,

                        },

                        timeSlotWrapper: () => null, // Hide timeslots
                        dayColumnWrapper: () => null, // Hide day columns
                        eventContainerWrapper: () => null, // Hide event container
                        timeGutterHeader: () => null,




                    }}
                />
                {!loader ? type === 'Unscheduled' ?

                    <UnScheduledJobs customStyle={{ left: 240, top: 72 }} noheader={true} onSubmit={()=>init()} /> : <GoogleMap
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{ width: "100vw", height: "100vh" }}
                        options={{
                            styles: mapStyles // Apply custom map styles
                        }}
                        zoom={4}

                    >


                        {tabData?.map((item, index) => (
                            <MarkerF
                                key={index}
                                position={item?.position}
                                onClick={() => {

                                    setActiveMarker(index)

                                }}
                            >
                                {activeMarker === index ? (
                                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                        <div style={{ width: '300px', height: '100%', overflowY: 'scroll', }}>
                                            <EventBox style={{ overflowY: 'scroll', paddingBottom: '20px' }}>
                                                <Typography variant="body2" color="textSecondary" style={{ color: palette.newBlue, textAlign: 'start', fontSize: '12px' }}>
                                                    {`${moment(item?.data?.date).format('DD MMM YYYY')}, ${item?.data?.timezone? moment(item?.data?.time).tz(item?.data?.timezone).format('hh:mm A'):moment(item?.data?.time).format('hh:mm A')}`}
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                                    <div>
                                                        <EventTitle>{item?.data?.name}</EventTitle>
                                                        {item?.data?.email && <StyledTypography style={{ marginBottom: '6px' }} variant="body2" color="textSecondary">
                                                            {(item?.data?.email)}
                                                        </StyledTypography>}
                                                        <StyledTypography variant="body2" color="textSecondary">
                                                            {formatMobileNumber(item?.data?.customerNumber)}
                                                        </StyledTypography>
                                                    </div>
                                                    <BookingStatus status={item?.data?.bookingStatus}>
                                                        <span style={{ marginRight: '5px' }}>●</span>
                                                        {item?.data?.bookingStatus === 'Waiting for confirmation' ? 'Open' : item?.data?.bookingStatus}
                                                    </BookingStatus>
                                                </div>
                                                <Divider />
                                                <LocationTitle>Location</LocationTitle>
                                                <StyledTypography >
                                                    {item?.data?.customerAddress}
                                                </StyledTypography>
                                                {item?.data?.team?.length > 0 && <>
                                                    <Divider />
                                                    <LocationTitle>Crew</LocationTitle>
                                                    {item?.data?.team?.map(item =>
                                                        <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                                            {item?.firstName} {item?.lastName}
                                                        </StyledTypography>
                                                    )}
                                                </>}
                                                {item?.data?.items?.length > 0 && <>
                                                    <Divider />
                                                    <LocationTitle>Line items</LocationTitle>
                                                    {item?.data?.items?.map(item =>
                                                        <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                                            {item?.name} x {item?.quantity}
                                                        </StyledTypography>
                                                    )}
                                                </>}
                                                {/* <Button
                        onClick={() => alert('Button clicked!')}
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 2 }}
                      >
                        Click Me
                      </Button> */}
                                            </EventBox>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', paddingTop: '10px', position: 'fixed', bottom: 5, background: '#fff' }}>

                                                <RippleButton
                                                    onClick={() => navigate(`/dashboard/calander/${item?.data?.referenceNo}`)}
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ width: '130px', fontWeight: '500', fontSize: '13px', }}
                                                >
                                                    View details
                                                </RippleButton>
                                            </div>
                                        </div>
                                    </InfoWindow>
                                ) : null}
                            </MarkerF>


                        ))}
                    </GoogleMap> : <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                </div>}




                {
                    ismodal && <FullScreenModal onClose={() => setIsModal(false)}>

                        <CreateBooking
                            value={value?.edit ? value : selectedEvent}
                            team={team}
                            refresh={() => init()}
                            serviceRequest={value?.edit ? false : true}
                            newService={true}
                            setCreateBookingModal={() => {

                                setIsModal(false)
                            }}
                            onSubmitsw={(val, editValue) => {

                                // sendchat(val, editValue)
                                init()
                                setIsModal(false)



                            }
                            }

                        />
                    </FullScreenModal>
                }
            </div>
        </Body>
    );
}
export default MapView;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:20px;
width:25px;
display:flex;
justify-content:center;
align-items:center;
font-size:12px;
padding:5px;
margin-right:12px;
border-radius:25px;

`
const EventBox = styled.div`
  padding: 4px;
overflow-y:scroll;
`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
  text-align:start;
`;
const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;
const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
  text-align:start;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  text-align:start;
  
`;

const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  text-decoration: ${({ status }) => status === 'Completed' && 'line-through'};
  margin-top: 5px;
  font-size: 12px;
  overflow: hidden;
  width:100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Icon = styled.img`
height:13px;

margin-right:10px;
padding-left:8px;
color:black
`

const CalanderTime = styled(Calendar)`
height:60px;
.rbc-time-content {
    display: none;
  }

.rbc-time-view .rbc-allday-cell{
    display: none;
}
.rbc-time-view .rbc-row{
    display: none; 
}

.rbc-timeslot-group {
    display: none;

  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    display: none;

}

 
  .rbc-events-container{
    display: none;
  }
  .rbc-calendar {
    height: 500px; 
  }
  


`

const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const Selectbox = styled(Select)`
padding-right:10px;
border-radius:8px;
text-align:start;
margin-left:10px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
