import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { submitUploadProfile, updateDetail, UploadImage, UploadNotesImage } from '../../store/Actions/dashboard.action'
import { formatMobileNumber } from '../../utils/Commonfunction'
import InputBox from '../../ReuseableComponets/InputBox'
import RippleButton from '../../ReuseableComponets/RippleButton'
import { palette } from '../../utils/Palette'
import { Slider, Typography } from '@mui/material'
import Cropper from 'react-easy-crop';
import { useCallback } from 'react'
import { getCroppedImg, getRotatedImage } from './component/CropImage'
import { getOrientation } from 'get-orientation/browser'
import TopModal from '../TopModal'
import Modal from '../Modal'
import { Cancel } from '@mui/icons-material'

function AccountDetails(props) {
    const detail = useSelector(state => state?.auth?.userDetails)
    const detaildet = useSelector(state => state?.auth?.userData)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [cropperSize, setcropperSize] = useState({})
    const [imagemodalOpen, setImageModalOpen] = useState(false)
    const [bannerImage, setbannerImage] = useState('');
    const [logoShow, setlogoShow] = useState(false);
    const [logoImage, setLogoImage] = useState('');
    const inputRef = React.useRef();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [bannerShow, setbannerShow] = useState(false);
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [values, setValues] = useState({
        'firstName': detail?.firstName,
        'lastName': detail?.lastName,
    })
    const [onFocus, setOnfocus] = useState(false)
    console.log(detaildet)
    const dispatch = useDispatch()

    const onPress = async () => {
        const res = await dispatch(updateDetail(values))
        setOnfocus(false)
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const ORIENTATION_TO_ANGLE = {
        '3': 180,
        '6': 90,
        '8': -90,
    }
    const onChangeBanner = async (e) => {
        setZoom(1)
        setRotation(0)
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setbannerImage(imageDataUrl);
        }

        setbannerShow(true);
    }
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }
    const onSelectFile = async (e) => {
        setZoom(1)
        setRotation(0)
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setLogoImage(imageDataUrl)
        }
        setlogoShow(true);

    }
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const renderBannerData = () => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '20px',background:'#fff', paddingRight: '20px' }}>
                    <h4>
                        Crop your image
                    </h4>
                    <Cancel onClick={() => setlogoShow(false)} style={{ cursor: 'pointer' }} />
                </div>
                <CropperModal>
                    <CropperContainer>
                        <Cropper
                            image={logoImage}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={cropperSize?.width / cropperSize?.height}
                            onMediaLoaded={({ naturalHeight, naturalWidth }) => {
                              setcropperSize({ height: naturalHeight, width: naturalWidth })
                            }}
                           
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            style={{
                                containerStyle: {
                                    backgroundColor: '#fff',
                                    fill: '#fff'
                                },
                                mediaStyle: {
                                    backgroundColor: '#fff',
                                    fill: '#fff'
                                }
                            }}
                            onZoomChange={setZoom}
                            cropShape='rect'
                            onRotationChange={setRotation}
                        />
                    </CropperContainer>
                    <ControlsContainer>
                        <Typography variant="overline" style={{ fontWeight: '600' }}>
                            Zoom
                        </Typography>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            style={{
                                marginRight: '15px',
                                marginLeft: 16,
                                color: palette.newBlue,
                                width: '180px',
                                marginBottom: '30px'
                            }}
                            onChange={(e, zoom) => setZoom(zoom)}
                        />

                       
                            <Typography variant="overline" style={{ fontWeight: '600' }}>
                                STRAIGHTEN
                            </Typography>
                            <Slider
                                style={{
                                    marginRight: '15px',
                                    marginLeft: 16,
                                    color: palette.newBlue,
                                    width: '180px'
                                }}
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                onChange={(e, rotation) => setRotation(rotation)}
                            />
                       
                        <p>Drag to reposition photo</p>
                    </ControlsContainer>


                </CropperModal>
                <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '0px', marginBottom: '15px', marginRight: '4%', justifyContent: 'flex-end', }}>
                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setlogoShow(false)}>Cancel</RippleButton>
                    <RippleButton onClick={async () => {
                        const imageList = []
                        const croppedImages = await getCroppedImg(
                            logoImage,
                            croppedAreaPixels,
                            rotation
                        )
                        const [metadata, base64Data] = croppedImages.split(',');
                        const mimeType = metadata.match(/:(.*?);/)[1];
                        // Convert base64 data to a byte array
                        const byteCharacters = atob(base64Data);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        // Create a Blob from the byte array
                        const blob = new Blob([byteArray], { type: metadata.split(':')[1].split(';')[0] });
                        const extension = mimeType.split('/')[1];
                        // Create a File from the Blob
                        const filee = new File([blob], "filename", { type: mimeType });


                        const res = await dispatch(UploadImage(filee));
                        const res1 = await dispatch(submitUploadProfile({
                            "logoUrl": res?.data[0]
                        }));
                        setLogoImage(false)

                    }}>Save</RippleButton>
                </div>
            </React.Fragment>
        )
    }

    return (
        <Main>

            <Header>Account</Header>
            <Name>Manage your Notifi profile</Name>
            <div style={{ display: 'flex', }}>
                <div style={{ width: '60%' }}>
                    <Wrapper style={{ display: 'flex' }}>
                        <div className='inputwrapper' style={{ marginRight: '60px' }}>

                            <InputBox header={"First name"} value={values?.firstName} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'flex-start', width: '100%' }} id="outlined-basic" size="small" placeholder="First name" variant="outlined" onChange={(e) => setValues({ ...values, firstName: e.target.value })} />
                        </div>
                        <div className='inputwrapper'>

                            <InputBox header={'Last name'} value={values?.lastName} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'flex-start', width: '100%' }} id="outlined-basic" size="small" placeholder="Last name" variant="outlined" onChange={(e) => setValues({ ...values, lastName: e.target.value })} />
                        </div>
                    </Wrapper>
                    {onFocus ? <div style={{ justifyContent: 'flex-end', width: '85%', display: 'flex', marginTop: '20px' }}>
                        <RippleButton onClick={() => setOnfocus(false)} style={{ background: '#fff', border: '1px solid #ddd', color: '#000', marginRight: '20px' }}>Cancel</RippleButton>
                        <RippleButton onClick={() => onPress()}>Save</RippleButton>
                    </div> : null}
                    <Wrapper style={{ display: 'flex' }}>
                        <div className='inputwrapper' style={{ marginRight: '60px' }}>
                            <InputBox header={"Email"} value={detail?.email} readOnly style={{ display: 'flex', alignSelf: 'flex-start', width: '100%', background: '#f3f3f3' }} id="outlined-basic" size="small" placeholder="Last name" variant="outlined" onChange={(e) => setValues({ ...values, lastName: e.target.value })} />
                        </div>
                        <div className='inputwrapper' >
                            <InputBox header={"Phone"} value={formatMobileNumber(detail?.phone)} readOnly style={{ display: 'flex', alignSelf: 'flex-start', width: '100%', background: '#f3f3f3' }} id="outlined-basic" size="small" placeholder="Last name" variant="outlined" onChange={(e) => setValues({ ...values, lastName: e.target.value })} />
                        </div>
                    </Wrapper>
                </div>
                <div style={{ width: '' }}>
                    {detaildet?.logoImage ? <ProfileImage  onClick={()=>setImageModalOpen(true)} src={detaildet?.logoImage} /> : <Profile>{getName(`${values?.firstName} ${values?.lastName}`)}</Profile>}
                    <div onClick={triggerFileSelectPopup} style={{ border: '1px solid #0F52BA', marginTop: '15px', padding: '7px 12px 7px 12px', display: 'flex', justifyContent: 'center', borderRadius: '20px' }}>
                        <h4 style={{ textAlign: 'start', fontSize: '13px', marginTop: '0px', marginBottom: '0px', color: palette.newBlue, cursor: 'pointer' }}>Select image</h4></div>
                </div>
                <input
                    type='file'
                    accept='image/*'
                    ref={inputRef}
                    onChange={onSelectFile}
                    style={{ display: "none" }}
                    onClick={(event) => {
                        event.target.value = null
                    }}
                />
            </div>
            {logoShow && logoImage &&
                <Modal customStyle={{ paddingLeft: '0px', paddingRight: '0px' }} onClose={() => setlogoShow(false)}>
                    {renderBannerData()}
                </Modal>


            }
            {imagemodalOpen && (
                <Modal
                    isOpen={imagemodalOpen}
                    customStyle={{background:'white'}}
                    onClose={() => setImageModalOpen(false)}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Cancel style={{cursor:'pointer'}} onClick={() => setImageModalOpen(false)}/>
                           
                    </div>

                    <Image  onClick={() => {
                        window.open(detaildet?.logoImage)
                    }} src={detaildet?.logoImage} />


                </Modal>
            )}
        </Main>
    )


}

export default AccountDetails;

const CropperModal = styled.div`
    width: 60vw;
    height: 60vh;
    background: #fff;
    display: flex;
  
    @media (max-width: 768px) {
        width: 90vw;
        height: 70vh;
    }
`;

const CropperContainer = styled.div`
    position: relative;
    width: 95%;
    height: 100%;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        height: 100%;
    }
`;

const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right:30px;
    width: 40%;
    @media (max-width: 768px) {
        width: 90%;
        margin-left: 0;
    }
`;
const Main = styled.div`
width:100%;
margin-top:-10px;
padding-top:25px;
flex-shrink: 0;
overflow: auto; 
padding-left:30px;
padding-bottom:50px;
  display: flex;
  flex-direction: column;

`
const Name = styled.p`
text-align:start;
font-size: 16px;
line-height: 21px;
color: rgb(45, 47, 49);
font-weight: 400;
margin-top:-15px;
margin-bottom:0px;
`
const Image = styled.img`
width:350px;

margin-top:20px;
margin-bottom:20px;

`

const Header = styled.p
    `
font-weight: 600;
font-size: 24px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #000;
margin-top:40px;
margin-right:10px;
`
const Profile = styled.div`
width:120px;
height:120px;
padding:3px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
margin-top:30px;
border-radius:60px;
background:#4169e1;

color:white;
font-weight:600;
font-size:24px;

`
const ProfileImage = styled.img`
width:120px;
height:120px;
display:flex;
cursor:pointer;
justify-content:center;
align-items:center;
text-align:center;
margin-top:30px;
border-radius:60px;
background:#fff;
border:1px solid #ddd;
color:white;
font-weight:600;
font-size:24px;

`
const Wrapper = styled.div`
display:block;
width:600px;
margin-top:15px;
.inputwrapper{
    width:47%;
    @media (min-width: 260px) and (max-width: 721px){
        width:38.5%;
       
        }
}
.button{
    margin-left:230px;
    width:300px;
}
@media (min-width: 260px) and (max-width: 721px){
    width:90%;
    
    }

`
const Inputbox = styled.input`
width:500px;
border-radius:5px;
font-size: 16px;
height:25px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding: 10px 14px;
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    margin-top:4px;
    align-self: flex-start;
    font-family:Inter;

  
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;

cursor:pointer;
width:60%;
:hover{
    background:#DDDDDD;
    border-radius:5px;
}
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 16px;
margin-top:20px;
width:90px;
height:44px;
border-radius:8px;
border:1px solid white;

color:white;
font-size:16px;
margin-bottom:10px;
cursor:pointer;

`