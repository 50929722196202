import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { Device } from '@twilio/voice-sdk';
import { getAllconversations } from "./Auth.action";
import { getUserDetails } from "./dashboard.action";


export const getChecklistItems = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getChecklist)
    return res
  }

  export const submitEditchecklist = (value) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.editChecklist}`,value)
    await dispatch(getUserDetails())
    return res
  }
  export const Deletechecklist = (id) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteChecklist}?checkListNo=${id}`)
  await dispatch(getUserDetails())
 
  return res
}
export const createChecklist = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.createChecklist, data)
    return res
  }

  export const stripeSession = () => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.stripeSession)
    return res
  }
