import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, TextField, Typography, IconButton, MenuItem, Select } from '@mui/material';
import DatePickerComponent from '../../../ReuseableComponets/DatePickerComponent';
import { colorThemes, formatMobileNumber, TimeListAm, TimeListPM } from '../../../utils/Commonfunction';
import { submitAddEvent, submitAddTask } from '../../../store/Actions/dashboard.action';
import { Cancel, Person, ScheduleOutlined } from '@mui/icons-material';
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { palette } from '../../../utils/Palette';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import moment from 'moment';
import { cross } from '../../../assets';
import InputBox from '../../../ReuseableComponets/InputBox';
const filter = createFilterOptions();
const AddTask = (props) => {
    const [addTaskLoader, setAddTaskLoader] = useState(false);
    const detailContact = useSelector(state => state?.auth?.allContacts)
    const [value3, setValue3] = useState(null)
    const [contactData, setContactData] = useState(detailContact?.length > 0 ? detailContact : [])
    const [error, setError] = useState(false);
    const [value2, setValue2] = useState(null)
    const [selectedTeam, setSelectedTeam] = React.useState([])
    const dispatch = useDispatch();
    const [taskValues, setTaskValues] = useState({
        date: props?.selectedSlot?.start?.toISOString(),
        endDate: props?.selectedSlot?.end?.toISOString(),
        time: moment(props?.selectedSlot?.start?.toISOString())?.format('hh:mm A'),
        endTime: moment(props?.selectedSlot?.end?.toISOString())?.format('hh:mm A'),
        taskDescription: '',
        name: '',
        number: '',
    });

    const handlePress = async () => {

        var newDate = taskValues?.time ? new Date() : '';
        var newDate1 = taskValues?.endTime ? new Date() : '';
        const selectedData = []
        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (taskValues?.time) {
            const val = taskValues.time.split(':');
            const val2 = val[1].split(' ');
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            if (val2[1] === 'AM' && hours === 12) {
                hours = 0;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }

        if (taskValues?.endTime) {
            const val3 = taskValues.endTime.split(':');
            const val4 = val3[1].split(' ');
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours1 < 12) {
                hours1 += 12;
            }
            if (val4[1] === 'AM' && hours1 === 12) {
                hours1 = 0;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }
        if(props?.header === 'Add Task'){
        const res = await dispatch(submitAddTask({ ...taskValues, "worker": selectedData, time: newDate, endTime: newDate1 }))
        props?.onSubmit()
    
    }
    else{
        const res = await dispatch(submitAddEvent({ ...taskValues, "worker": selectedData, time: newDate, endTime: newDate1,name:'',number:'',description:taskValues?.taskDescription }))
        props?.onSubmit()
    }


    };



    return (
        <Container>
            <Header>

                <Cancel style={{ marginRight: '15px' }} onClick={()=>props?.onClose()} />
                <Typography style={{ fontSize: '18px', fontWeight: '550' }} variant="h5">{props?.header}</Typography>
            </Header>

            <FormContainer>
                {props?.header === 'Add Task' && (taskValues?.number ? <>
                    <Typography style={{ textAlign: 'start', marginBottom: '0px', fontSize: '16px', fontWeight: '550' }} variant="h6">{taskValues?.name}</Typography>
                    <Typography style={{ textAlign: 'start', marginTop: '-15px', fontSize: '13px' }} variant="body1">
                        {`${formatMobileNumber(taskValues?.number || '')}`}
                    </Typography>
                </> :

                    <div style={{ marginBottom: '5px', marginTop: '5px' }} className='authoCompletewrapper'>
                        <Autocomplete1
                            value={value2}
                            onChange={(event, newValue) => {
                                const searchString = 'Add new contact';
                                const result = newValue?.name
                                if (newValue && newValue?.name?.includes(searchString)) {
                                    // setOpenAddContact(true)
                                }

                                else if (typeof newValue?.name === 'string') {

                                    setValue2(result);
                                    setTaskValues({ ...taskValues, name: newValue?.name, number: newValue?.number })
                                    // setSelectedContact({ ...newValue, mainAddress: newValue?.mainAddress, additionalAddress: newValue?.address ? [...newValue?.additionalAddress, { address: newValue?.address }] : [...newValue?.additionalAddress] })
                                    // setEditValue({
                                    //     name: newValue?.name,
                                    //     address: newValue?.address,
                                    //     email: newValue?.email,
                                    //     businessName: newValue?.businessName,
                                    //     to: newValue?.number,
                                    //     mainAddress: newValue?.mainAddress
                                    // })
                                }
                                else {
                                    setValue2(result)
                                    setTaskValues({ ...taskValues, name: newValue?.name, number: newValue?.number })
                                    // setEditValue(newValue)
                                }

                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                filtered.push({ name: `Add new contact` });
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={contactData}
                            getOptionLabel={(option) => {
                                if (typeof option?.name === 'string') {
                                    return option.name;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option;
                            }}
                            renderOption={(props, option) => <div style={{ flexDirection: 'column' }} {...props}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                    <Person style={{ fontSize: '16px', marginRight: '5px' }} />
                                    <ContactLabel>{option?.name}</ContactLabel>
                                </div>
                                <ContactLabel style={{ marginBottom: '0px', fontSize: '12px', color: palette.grey }}>{formatMobileNumber(option?.number)}</ContactLabel>
                            </div>}
                            sx={{
                                width: '100%', '& input': {
                                    height: '5px',
                                },
                            }}
                            freeSolo
                            style={{ height: 30, }}
                            renderInput={(params) => (<div><TextField onFocus={() => {
                                if (error) {
                                    setError(false)
                                }
                            }} style={{ fontSize: '13px', border: error && '1px solid red', borderRadius: '4px' }} {...params} placeholder="Add contact" /></div>)}
                        />
                    </div>)}

               {props?.header !== 'Add Task' && <InputBox
                    style={{ width: '91%' }}
                    placeholder={'Title'}
                    onChange={(e) => setTaskValues({ ...taskValues, title: e.target.value })}
                />}

                <InputContainer>
                    <DatePickerComponent
                        label="Start date"
                        selectedDate={taskValues.date}
                        onChange={(date) => setTaskValues({ ...taskValues, date })}
                    />
                    < div style={{ position: 'relative' }}>
                        <Label style={{ fontWeight: '600' }}>Start time</Label>
                        <SelectTimebox
                            style={{ marginLeft: '0px' }}

                            renderValue={() =>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                    {taskValues.time || 'Select time'}
                                    <ScheduleOutlined style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                </div>
                            } defaultValue={"REAL_ESTATE"} onChange={(event) => setTaskValues({ ...taskValues, time: event.target.value })}>
                            {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                            )}
                        </SelectTimebox>
                    </div>
                </InputContainer>
                <InputContainer>
                    <DatePickerComponent
                        label="End date"
                        selectedDate={taskValues.endDate}
                        onChange={(date) => setTaskValues({ ...taskValues, date })}
                    />
                    < div style={{ position: 'relative' }}>
                        <Label style={{ fontWeight: '600' }}>End time</Label>
                        <SelectTimebox
                            style={{ marginLeft: '0px' }}

                            renderValue={() =>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                    {taskValues?.endTime || 'End time'}
                                    <ScheduleOutlined style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                </div>
                            } defaultValue={"REAL_ESTATE"} onChange={(event) => setTaskValues({ ...taskValues, endTime: event.target.value })}>
                            {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                            )}
                        </SelectTimebox>
                    </div>
                </InputContainer>

                <InputText
                    placeholder="Description"
                    type="text"
                    value={taskValues.taskDescription}
                    onChange={(e) =>
                        setTaskValues({ ...taskValues, taskDescription: e.target.value })
                    }
                />
                <div style={{ marginBottom: '5px', marginTop: '5px' }} className='authoCompletewrapper'>
                    <Autocomplete1
                        value={value3}
                        onChange={(event, newValue) => {

                            const searchString = 'Add new contact';

                            if (newValue && newValue?.firstName?.includes(searchString)) {

                                // setOpenAddContact(true)
                            }

                            else if (typeof newValue?.firstName === 'string') {
                                //setValue2(result);
                                setSelectedTeam([...selectedTeam, newValue])

                                // setSelectedHash([...selectHash, result])
                            }
                            else {
                                //setValue2(result)
                                setSelectedTeam([...selectedTeam, newValue])
                                //  setHasTag([...selectHash,result])
                            }

                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option?.firstName);

                            // filtered.push({ name: `Add new contact` });


                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={props?.team || []}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option?.firstName === 'string') {
                                return option.firstName;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option;
                        }}
                        renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option?.firstName}</li>}
                        sx={{
                            width: '100%', '& input': {
                                height: '5px', // Define the height of the input box here
                            },
                        }}
                        freeSolo
                        style={{ height: 30, marginBottom: '20px', fontSize: '13px' }}
                        renderInput={(params) => (
                            <div>
                                <TextField style={{ fontSize: '13px' }} {...params}


                                    placeholder="Add crew member" />

                            </div>
                        )}
                    />
                    {selectedTeam?.length > 0 ? <WrapperField style={{ marginTop: '0px' }}>
                        {selectedTeam?.map((item, index) =>
                            <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, }}>{item?.firstName} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                const arr = selectedTeam?.filter(obj => obj?.email !== item?.email)
                                setSelectedTeam(arr)
                            }} src={cross} /></WrapperContainer>
                        )}
                    </WrapperField> : null}
                    {/* <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '30px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {

                                    setBoolValue({ ...boolValue, crew: false })
                                }}>Cancel</SendButton1>
                                <SendButton1 style={{ marginRight: -15 }} onClick={() => {

                                    setBoolValue({ ...boolValue, crew: false })
                                    // setValue({ ...value, ...editValue })
                                    // setEditDetail(false)
                                }}>Save</SendButton1>
                            </div> */}
                </div>

                {error && <ErrorMessage>Add contact ⚠</ErrorMessage>}

                <ButtonContainer>
                    <RippleButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ width: '100%', height: '40px' }}
                        onClick={async () => handlePress()}
                        disabled={addTaskLoader}
                    >
                        {addTaskLoader ? 'Adding...' :props?.header==='Add Task'? 'Add Task':'Add Event'}
                    </RippleButton>
                </ButtonContainer>
            </FormContainer>
        </Container>
    );
};

export default AddTask;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top:10px;
  margin-left:-15px;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputContainer = styled.div`
  display: flex;
justify-content:space-between;
  gap: 20px;
`;

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-top: 0px;
  margin-bottom:10px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
`;

const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.renderdiv{
    display:flex;
    justify-content:space-between; 
    align-items:center; 
    font-size:13px; 
}
.select-timebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-size: 13px;
    position: relative;
}

.schedule-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const ContactLabel = styled.p`
color:${palette.black};
font-size: 13px;
text-align:start;
line-height:16px;
width:100%;
margin-top:0px;
margin-bottom:4px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

.nameButtton{
    font-weight:600;
    color:${palette.charcoleBlack}
}

`
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`

const WrapperField = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 10px;

`;
const WrapperContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
`;

const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 93%;


  display: block;
  border-radius: 4px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;