import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { getAllconversations } from "./Auth.action";
import { getUserDetails } from "./dashboard.action";

export const submitAdedVisit = (data, id) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.addVisit}?referenceNo=${id}`, data)
    return res
}
export const submitEditVisit = (data, id,visitId) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.editVisit}?referenceNo=${id}&multiVistId=${visitId}`, data)
    return res
}

