import logo from '../assets/notifilogo.png'
import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuotedata } from '../store/Actions/Auth.action';
import { taxprice, totalprice, formatMobileNumber, calculateTotalTaxAmount } from '../utils/Commonfunction';
import moment from 'moment';
import { palette } from '../utils/Palette';
import NewInvoice from './NewInvoice';
import { pdf } from '@react-pdf/renderer';
const PreviewInvoice = (props) => {
    let location = useLocation()

    let { id } = useParams()
    //const windowUrl = window.location.search;
    //const params = new URLSearchParams(windowUrl);
    const searchParams = new URLSearchParams(location.search);
    const detail = useSelector(state => state?.auth?.userData)
    const referenceNo = searchParams.get('referenceNo');
    const userData = useSelector(state => state?.auth?.userData)
    const [taxval, setTaxVal] = useState([])
    const email = searchParams.get('email');
    const [typesign, setTypeSign] = useState('')
    //let quote = location.pathname
    //let refnumber = quote?.[3]
    // console.log(windowUrl,"num")
    let dispatch = useDispatch()

    const init = async () => {
        await dispatch(getQuotedata({ referenceNo: id }))
        props?.setloader()
    }
    useEffect(() => {
        signType()
        init()

    }, [])



    const quotedata = useSelector(state => state?.auth?.quote)

    const flattenItems = (items) => {
        return items.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };

    const calculateTotalAmount = (items) => {
        const flattenedItems = flattenItems(items);
        return `${([...flattenedItems].reduce(function (accumulator, currentValue) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
        }, 0) - discountvalue() + calculateTotalTaxAmount([...flattenedItems], discountvalue()))?.toFixed(2)}`
    };

    const calc = () => {
        const totalTax = {};
        const totalPrices = {}; // To accumulate the total prices for each tax
        const discountAmount = parseFloat(discountvalue()) || 0;
        const items = flattenItems([...props?.quoteData, ...props?.materialData, ...props?.bundleData]);

        // Step 1: Calculate the total price of all items
        const totalOriginalPrice = items.reduce((acc, item) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);
            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return acc;
            }
            return acc + (price * quantity);
        }, 0);

        // Step 2: Calculate the weightage and apply the discount accordingly
        items.forEach(item => {
            const taxName = item.taxName;
            const taxAmount = parseFloat(item.tax);
            const taxNumber = item?.taxNumber || '';
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);

            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return;
            }

            const totalPrice = price * quantity;
            const weightage = totalPrice / totalOriginalPrice;
            const discountForItem = discountAmount * weightage;
            const discountedPrice = totalPrice - discountForItem;

            if (!isNaN(taxAmount) && taxAmount > 0) {
                const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places

                if (totalTax.hasOwnProperty(taxName)) {
                    totalTax[taxName].taxAmount += taxValue;
                    totalTax[taxName].taxPercentage = taxAmount;
                    totalTax[taxName].taxNumber = taxNumber;
                    totalPrices[taxName] += discountedPrice;
                } else {
                    totalTax[taxName] = {
                        taxAmount: taxValue,
                        taxName,
                        taxPercentage: taxAmount,
                        taxNumber
                    };
                    totalPrices[taxName] = discountedPrice;
                }
            }
        });

        const totalTaxArray = Object.entries(totalTax)?.map(([taxName, { taxAmount, taxPercentage, taxNumber }]) => ({
            taxName,
            taxAmount: parseFloat(taxAmount.toFixed(2)), // Ensure rounding to 2 decimal places
            taxNumber,
            taxPercentage,
            totalPrice: parseFloat(totalPrices[taxName].toFixed(2)) // Ensure rounding to 2 decimal places
        }));

        setTaxVal(totalTaxArray);
    };




    const getTotalTax = () => {
        const discountAmount = parseFloat(discountvalue()) || 0;
        const items = [...props?.quoteData, ...props?.materialData];

        // Calculate the total price of all items
        const totalOriginalPrice = items.reduce((acc, item) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);
            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return acc;
            }
            return acc + (price * quantity);
        }, 0);

        let totalTaxAmount = 0;

        // Calculate the weightage and apply the discount accordingly
        items.forEach(item => {
            const taxAmount = parseFloat(item.tax);
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);

            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return;
            }

            const totalPrice = price * quantity;
            const weightage = totalPrice / totalOriginalPrice;
            const discountForItem = discountAmount * weightage;
            const discountedPrice = totalPrice - discountForItem;

            if (!isNaN(taxAmount) && taxAmount > 0) {
                const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places
                totalTaxAmount += taxValue;
            }
        });

        return parseFloat(totalTaxAmount.toFixed(2)); // Ensure rounding to 2 decimal places
    };

    useEffect(() => {
        calc()

    }, [props?.quoteData])

    console.log(quotedata, 'quotedata')
    const styles = {
        page: {
            marginLeft: '3rem',
            marginRight: '3rem',
            'page-break-after': 'always',

        },

        columnLayout: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '2rem 0 2rem 0',
            marginBottom: '50px',
            gap: '2rem',
        },

        column: {
            display: 'flex',
            flexDirection: 'column',
        },

        spacer2: {
            height: '2rem',
        },

        fullWidth: {
            //width: '100%',
            marginTop: '20px',
            height: '40px'
        },

        marginb0: {
            marginBottom: 0,
        },
        text: {
            fontSize: 14,
            fontWeight: '500',

        },
        text2: {
            fontSize: 14,
            textAlign: 'start',
            fontWeight: 'bold',
            marginBottom: 5,
            marginTop: 5,
            width: '150px',


        }
        , text1: {
            marginTop: '-10px',
            textAlign: 'start',
            fontSize: 14,
            fontWeight: '500',

        }
    };

    const getToken = () => {
        const token = localStorage.getItem('sign')

        return token ? ` ${token}` : ""
    }
    const signType = () => {
        const token = localStorage.getItem('signType')

        setTypeSign(token)

    }
    const numberClean = (cleanText) => {
        const formatted = cleanText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return parseFloat(formatted);
    }
    const putCommaInvalue = (price) => {

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formatted;
    }
    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const discountvalue = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (props?.userDetails?.discount) {
            if (props?.userDetails?.discountType === '$') {
                discountedTotalValue = parseFloat(props?.userDetails?.discount);
                discountPercentage = (props?.userDetails?.discount / (flattenItems([...props?.quoteData, ...props?.materialData, ...props?.bundleData]).reduce(function (accumulator, currentValue) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }, 0)?.toFixed(2)) * 100)
            }

            else if (props?.userDetails?.discountType === '%') {
                discountedTotalValue = (flattenItems([...props?.quoteData, ...props?.materialData, ...props?.bundleData]).reduce(function (accumulator, currentValue) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }, 0)?.toFixed(2) * (props?.userDetails?.discount / 100));
                discountPercentage = parseFloat(props?.userDetails?.discount);
            }
        }

        return discountedTotalValue?.toFixed(2);
    };


    return (
        <MainContainer>
            <div style={styles.page}>
                <div style={{ ...styles.columnLayout, justifyContent: 'space-between' }}>
                    <div style={{ width: '100%' }}>
                        <h1 style={{ marginTop: '0px', marginBottom: '10px', fontWeight: 'bold', fontSize: 28, textAlign: 'start', }}>
                            {props?.type || "Invoice"}
                        </h1>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{props?.type ? "Quote number" : "Invoice number"}</p>
                            <p style={styles.text2}>{props?.type ? `#${props?.userDetails?.quoteNumber || props?.userDetails?.numberOfQuote}` : props?.userDetails?.invoiceNumber || props?.userDetails?.numberOfInvoice ? `#${props?.userDetails?.invoiceNumber || props?.userDetails?.numberOfInvoice}` : ''}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Date of Issue"}</p>
                            <p style={styles.text2}>{props?.userDetails?.date ? moment(props?.userDetails?.date).format('MMMM DD, YYYY') : moment(quotedata?.date).format('MMMM DD, YYYY')}</p>
                        </div>
                        {taxval?.map((item) => (item?.taxNumber &&
                            <div style={{ display: 'flex' }}>
                                <p style={styles.text2}>{item?.taxName}</p>
                                <p style={styles.text2}>{item?.taxNumber}</p>
                            </div>
                        ))}
                      { props?.userDetails?.serviceAddress&&   <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Service Address"}</p>
                            <p style={{...styles.text2,width:'50%'}}>{props?.userDetails?.serviceAddress}</p>
                        </div>}

                    </div>
                    {<div style={{ display: 'flex', alignItems: 'start' }}>
                        {userData?.logoImage ? <ProfilePic src={userData?.logoImage} /> : <OutProfile>
                            {getName(userData?.businessName)}</OutProfile>}

                    </div>}
                    <div >
                        {/* <h1 style={{ marginBottom: '10px', textAlign: 'start', }}>
                            {"Invoice"}
                        </h1> */}
                        {/* <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Invoice number"}</p>
                            <p style={styles.text2}>#{quotedata?.numberOfInvoice}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Date of Issue"}</p>
                            <p style={styles.text2}>{moment(quotedata?.date).format('MMMM DD, YYYY')}</p>
                        </div> */}
                        {/* <div style={{ display: 'flex',flexDirection:'column' }}>

                            <p style={{ ...styles.text2,width:'auto', fontSize: '20px',marginBottom:'15px' }}>{userData?.businessName}</p>
                            
                             <p style={styles.text1}>{'#1104 - 8570 Ruvergrass Dr., Vancouver, BC. V5S 0H4 '} </p>
                        <p style={styles.text1}>{userData?.email} </p>
                        </div> */}
                    </div>


                </div>
                {/* <div style={styles.columnLayout}>
                    <div >
                        <h1 style={{ marginBottom: '50px', textAlign: 'start', fontFamily: 'Inter' }}>
                            {props?.type || "Invoice"}
                        </h1>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{props?.type ? "Quote number" : "Invoice number"}</p>
                            <p style={styles.text2}>{props?.type ? `#${props?.userDetails?.quoteNumber}` : props?.userDetails?.invoiceNumber || props?.userDetails?.numberOfInvoice ? `#${props?.userDetails?.invoiceNumber || props?.userDetails?.numberOfInvoice}` : ''}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Date of Issue"}</p>
                            <p style={styles.text2}>{props?.userDetails?.date ? moment(props?.userDetails?.date).format('MMMM DD, YYYY') : moment(quotedata?.date).format('MMMM DD, YYYY')}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Organization"}</p>
                            <p style={styles.text2}>{userData?.businessName}</p>
                        </div>
                    </div>
                    {<div style={{ display: 'flex', alignItems: 'start' }}>
                        {userData?.logoImage ? <ProfilePic src={userData?.logoImage} /> : <OutProfile>
                            {getName(userData?.businessName)}</OutProfile>}


                    </div>}
                </div> */}

                <Container style={{ justifyContent: 'space-between' }} >
                    <div className='detailCont' >
                        <h4 style={{ fontSize: 14, marginBottom: '10px' }}>{userData?.businessName}</h4>
                        <p style={styles.text}>{formatMobileNumber(`+${localStorage.getItem('number')}`)}</p>
                        {userData?.email && <p style={styles.text1}>{userData?.email} </p>}
                        {userData?.address && <p style={styles.text1}>{userData?.address} </p>}

                    </div>
                    <div className='detailCont'>
                        <h4 style={{ fontSize: 14, marginBottom: '10px' }}>Bill to</h4>
                        {props?.userDetails?.name ? <p style={styles.text}>{props?.userDetails?.name ? props?.userDetails?.name : '-'}</p> : null}
                        {props?.userDetails?.phoneNumber ? <p style={styles.text1}>{props?.userDetails?.phoneNumber ? props?.userDetails?.phoneNumber : '-'}</p> : null}
                        {props?.userDetails?.email ? <p style={styles.text1}>{props?.userDetails?.email ? props?.userDetails?.email : '-'}</p> : null}
                        {props?.userDetails?.address ? <p style={styles.text1}>{props?.userDetails?.address ? props?.userDetails?.address : '-'}</p> : null}
                        {/* <p style={styles.text1}> Delaware 19709United States</p> */}

                    </div>
                </Container>
                {/* <img style={styles.fullWidth} src="photo-2.png" /> */}
            </div>

            <div style={styles.page}>
                {/* <div>
                    <h3 style={{ textAlign: 'center' }}>Thank you for your business! </h3>
                </div> */}

                <div >

                    {props?.userDetails?.notesInvoice && <BottomMessageContainer>
                        <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Message</h3>
                        <BottomMessageText>{props?.userDetails?.notesInvoice}</BottomMessageText>
                    </BottomMessageContainer>}
                    {props?.userDetails?.notesQuote && <BottomMessageContainer>
                        <h3 style={{ textAlign: 'start' }}>Message</h3>
                        <BottomMessageText>{props?.userDetails?.notesQuote}</BottomMessageText>
                    </BottomMessageContainer>}
                    {
                        props?.bundleData?.length > 0 &&
                        <Table style={{ marginBottom: '20px' }}>

                            <tr style={{ backgroundColor: '#fff', background: '#DEDEDE' }}>
                                {/* <td>Name</td>
                        <td></td> */}
                                <td className='tdtitlw'>{"Items"}</td>


                                {/* <td></td> */}
                                {/* <td></td>
                            <td></td> */}
                                <td style={{ width: '130px' }} className='tdtitlw'>{"Quantity"}</td>



                                <td style={{ width: '130px' }} className='tdtitlw'>{"Unit price"}</td>




                                <td style={{ width: '130px' }} className='tdtitlw'>{"Amount"}</td>

                            </tr>

                            {props?.bundleData?.map((x, index) => {
                                return (x?.optional ? null : <>

                                    <tr style={{ backgroundColor: '#fff' }}>
                                        {/* <td>{x?.name ? x?.name : '-'}</td>
        <td></td> */}

                                        <td >
                                            <div>
                                                {x?.picUrl && <ItemImg src={x?.picUrl} />}
                                                <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {x?.name ? `${x?.name}` : '-'}</p>
                                                {x?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{x?.description ? `${x?.description}` : '-'}</p>}
                                            </div>
                                        </td>
                                        <td >
                                            <div className='valuetd'>
                                                {/* {x?.quantity ? putCommaInvalue(x?.quantity) : '1'} */}
                                            </div>
                                        </td>
                                        <td sclassName='valuetd' >
                                            <div className='valuetd'>
                                                {/* ${x?.price ? parseFloat(x.price)?.toFixed(2) : '0'} */}
                                            </div>
                                        </td>
                                        <td className='valuetd' >
                                            <div className='valuetd'>
                                                ${x?.price && x?.quantity ? putCommaInvalue((x?.price * x?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(x?.price)?.toFixed(2))}
                                            </div>
                                        </td>


                                    </tr>
                                    {x?.categoryVisiblity !== 'Items are NOT visible to your customer' &&
                                        x?.lineItems?.map((item, index) =>
                                            <tr style={{ backgroundColor: '#fff' }}>
                                                {/* <td>{x?.name ? x?.name : '-'}</td>
        <td></td> */}

                                                <td >
                                                    <div>
                                                        {item?.picUrl && <ItemImg src={item?.picUrl} />}
                                                        <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '500' }}> {item?.name ? `${item?.name}` : '-'}</p>
                                                        {item?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{item?.description ? `${item?.description}` : '-'}</p>}
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className='valuetd' style={{ fontWeight: '500' }}>
                                                        {item?.quantity ? putCommaInvalue(item?.quantity) : '1'}
                                                    </div>
                                                </td>
                                                <td sclassName='valuetd' >
                                                    {x?.categoryVisiblity !== 'Items are visible, but item prices are NOT visible to your customer' && <div className='valuetd' style={{ fontWeight: '500' }}>
                                                        ${item?.price ? parseFloat(item.price)?.toFixed(2) : '0'}
                                                    </div>}
                                                </td>
                                                <td className='valuetd' >
                                                    {x?.categoryVisiblity !== 'Items are visible, but item prices are NOT visible to your customer' && <div className='valuetd' style={{ fontWeight: '500' }}>
                                                        ${item?.price && item?.quantity ? putCommaInvalue((item?.price * item?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(item?.price)?.toFixed(2))}
                                                    </div>}
                                                </td>


                                            </tr>)
                                    }

                                </>
                                )
                            })}

                        </Table>
                    }
                    {props?.quoteData?.length > 0 && <Table>

                        <tr style={{ backgroundColor: '#fff', background: '#DEDEDE' }}>
                            {/* <td>Name</td>
                        <td></td> */}
                            <td className='tdtitlw'>{"Service items"}</td>


                            {/* <td></td> */}
                            {/* <td></td>
                            <td></td> */}
                            <td style={{ width: '130px' }} className='tdtitlw'>{"Quantity"}</td>



                            <td style={{ width: '130px' }} className='tdtitlw'>{"Unit price"}</td>




                            <td style={{ width: '130px' }} className='tdtitlw'>{"Amount"}</td>

                        </tr>

                        {props?.quoteData?.map((x, index) => {
                            return (x?.optional ? null : <>

                                <tr style={{ backgroundColor: '#fff', }}>
                                    {/* <td>{x?.name ? x?.name : '-'}</td>
        <td></td> */}

                                    <td >
                                        <div>
                                            {x?.picUrl && <ItemImg src={x?.picUrl} />}
                                            <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {x?.name ? `${x?.name}` : '-'}</p>
                                            {x?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{x?.description ? `${x?.description}` : '-'}</p>}
                                        </div>
                                    </td>
                                    <td >
                                        <div className='valuetd'>
                                            {x?.quantity ? putCommaInvalue(x?.quantity) : '1'}
                                        </div>
                                    </td>
                                    <td sclassName='valuetd' >
                                        <div className='valuetd'>
                                            ${x?.price ? parseFloat(x.price)?.toFixed(2) : '0'}
                                        </div>
                                    </td>
                                    <td className='valuetd' >
                                        <div className='valuetd'>
                                            ${x?.price && x?.quantity ? putCommaInvalue((x?.price * x?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(x?.price)?.toFixed(2))}
                                        </div>
                                    </td>


                                </tr>

                            </>
                            )
                        })}

                    </Table>}
                    {props?.materialData?.length > 0 && <Table style={{ marginTop: '20px' }}>

                        <tr style={{ backgroundColor: '#fff', background: '#DEDEDE' }}>
                            {/* <td>Name</td>
<td></td> */}
                            <td className='tdtitlw'>{"Material Items"}</td>


                            {/* <td></td> */}
                            {/* <td></td>
    <td></td> */}
                            <td style={{ width: '130px' }} className='tdtitlw'>{"Quantity"}</td>



                            <td style={{ width: '130px' }} className='tdtitlw'>{"Unit price"}</td>




                            <td style={{ width: '130px' }} className='tdtitlw'>{"Amount"}</td>

                        </tr>

                        {props?.materialData?.map((x, index) => {
                            return (x?.optional ? null : <>

                                <tr style={{ backgroundColor: '#fff', }}>
                                    {/* <td>{x?.name ? x?.name : '-'}</td>
<td></td> */}

                                    <td >
                                        <div>
                                            {x?.picUrl && <ItemImg src={x?.picUrl} />}
                                            <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {x?.name ? `${x?.name}` : '-'}</p>
                                            {x?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{x?.description ? `${x?.description}` : '-'}</p>}
                                        </div>
                                    </td>
                                    <td >
                                        <div className='valuetd'>
                                            {x?.quantity ? putCommaInvalue(x?.quantity) : '1'}
                                        </div>
                                    </td>
                                    <td sclassName='valuetd' >
                                        <div className='valuetd'>
                                            ${x?.price ? parseFloat(x.price)?.toFixed(2) : '0'}
                                        </div>
                                    </td>
                                    <td className='valuetd' >
                                        <div className='valuetd'>
                                            ${x?.price && x?.quantity ? putCommaInvalue((x?.price * x?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(x?.price)?.toFixed(2))}
                                        </div>
                                    </td>


                                </tr>

                            </>
                            )
                        })}
                    </Table>}
                    <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'flex-end', width: '100%', fontSize: '12px', fontWeight: '600' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                            <p>Subtotal</p>
                            <p>{`$${putCommaInvalue(flattenItems([...props?.quoteData, ...props?.materialData, ...props?.bundleData])?.reduce(function (accumulator, currentValue) {
                                if (!currentValue?.optional) {
                                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                }
                                return accumulator
                            }, 0)?.toFixed(2))}`}</p>
                        </div>
                    </div>
                    {taxval?.map((item) => (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: '12px', fontWeight: '600' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                                <div style={{ textAlign: 'start' }}>
                                    <p>{item?.taxName} ({item?.taxPercentage}%)</p>
                                    {/* <p>{item?.taxNumber}</p> */}
                                </div>
                                <p>{
                                    `$${putCommaInvalue(parseFloat(item?.taxAmount)?.toFixed(2))}`}</p>
                            </div>
                        </div>)
                    )}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: '12px', fontWeight: '600' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                            <p>Discount</p>
                            <p>${discountvalue()}</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: '12px', fontWeight: '600' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                            <p>Total</p>
                            <p>{`$${putCommaInvalue(calculateTotalAmount([...props?.quoteData, ...props?.materialData, ...props?.bundleData]))}`}</p>
                        </div>
                    </div>
                   {props?.userDetails?.attachment?.length>0&& <BottomMessageContainer>
                    <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Attachments / Photos</h3>
                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '10px' }}>
                            {props?.userDetails?.attachment?.map((item) =>
                                <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                    <PhotosStyle src={item} />

                                </div>
                            )}


                        </div>

                    </div>
                    </BottomMessageContainer>}

                    {!props?.type && detail?.termCondition?.[0]?.invoice && <BottomMessageContainer>
                        <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Terms & Conditions</h3>

                        <BottomMessageText>{detail?.termCondition?.[0]?.invoice}</BottomMessageText>
                    </BottomMessageContainer>}
                    {props?.type && detail?.termCondition?.[0]?.quotes && <BottomMessageContainer>
                        <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Terms & Conditions</h3>

                        <BottomMessageText>{detail?.termCondition?.[0]?.quotes}</BottomMessageText>
                    </BottomMessageContainer>}


                    {/* <Container >
                        <div >
                            <div style={{ width: '250px', borderBottom: '2px solid #000', display: 'flex', paddingTop: '60px', alignItems: 'center', }}>
                                <p style={{ fontSize: '12px', alignSelf: 'flex-end' }}>{moment(new Date()).format('DD MMM YYYY')}</p>
                                <img style={{ fontFamily: 'Monsieur' }} src={getToken()} className={typesign === "draw" ? "signature" : "signature1"} alt="logo" />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <p style={{ fontSize: '12px', marginRight: '70px', fontWeight: 'bold' }}>Date</p>
                                <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Signature</p>
                            </div>
                        </div>
                    </Container> */}


                </div>



            </div>
        </MainContainer>
    );
};

export default PreviewInvoice;
const MainContainer = styled.div`
overflow:auto;
page-break-after:always;
width:225mm;
height:297mm;
position:relative;
`

const Container = styled.div`
display:flex;
 gap:2rem;
 margin-bottom:25px;
 .detailCont{
     width:40%;
     text-align:start;
 }
 .signature {
    width: 120px;
    height: 52px;
    margin-top: 20px;
  
    padding: 15px;
    object-fit: contain;
  }
  .signature1 {
    width: 150px;
    height: 70px;
    margin-top: 20px;
    position:absolute;
    margin-bottom:20px;
    left:150px;
    padding: 15px;
    object-fit: contain;
  }
`
const OutProfile = styled.div`
background:${palette.newBlue};
color:white;
width:100px;
height:100px;
display:flex;
justify-content:center;
align-self:start;
margin-right:-20px;
align-items:center;
font-size:28px;
font-weight:bold;


`
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: start;
  font-size: 11px;
  color: black;

  .divider {
    font-weight: 300;
    line-height: 235%;
  }

  tr:nth-child(odd) {
    background-color: #FAFAFA;
  }

  th:first-child {
    border-radius: 10px 0 0 0px;
  }

  th:last-child {
    border-radius: 0 10px 0px 0;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid #ddd;
    font-style: normal;
    font-weight: 600;
    vertical-align: top;
    
  }

  .tdtitle {
    font-size: 14px;
    font-weight: 600;
    color: gray;
  }

  .totaltd {
    font-weight: 600;
  }
`;


const ProfilePic = styled.img`
background:#fff;
color:white;
width:100px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:-20px;
font-weight:bold;
`
const ItemImg = styled.img`
background:#fff;
color:white;
width:50px;
margin-bottom:5px;
height:50px;
display:flex;
justify-content:center;
align-items:center;
font-weight:bold;
`
const BottomMessageContainer = styled.div`
margin-top:10px;
text-align:start;
`;

const BottomMessageText = styled.p`
    font-size: 14px;
    color: ${palette.black};
    line-height:1.2;
    white-space: pre-line;
    margin-bottom:40px;
    font-weight:500;

`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:15px;
margin-bottom:10px;
width: 120px;
height: 120px;

`