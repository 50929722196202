import React, { Fragment, useEffect, useState } from 'react'
import { Image, Text, View, Page, Document, StyleSheet, Font } from '@react-pdf/renderer';

import logo from '../assets/logo/Logo.png'
import { calculateTotalTaxAmount, formatMobileNumber, putCommaInvalue, totalprice } from '../utils/Commonfunction';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { palette } from '../utils/Palette';
import interRegular from '../assets/fonts/Inter-Regular.ttf'
const NewInvoice = (props) => {
    const [taxval, setTaxVal] = useState([])
    const flattenItems = (items) => {
        return items?.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };
    const calc = () => {
        const totalTax = {};
        const totalPrices = {}; // To accumulate the total prices for each tax
        const discountAmount = parseFloat(applyDiscount()) || 0;
        const items = flattenItems([...props?.data?.items]);

        // Step 1: Calculate the total price of all items
        const totalOriginalPrice = items.reduce((acc, item) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);
            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return acc;
            }
            return acc + (price * quantity);
        }, 0);

        // Step 2: Calculate the weightage and apply the discount accordingly
        items.forEach(item => {
            const taxName = item.taxName;
            const taxAmount = parseFloat(item.tax);
            const taxNumber = item?.taxNumber || '';
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);

            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return;
            }

            const totalPrice = price * quantity;
            const weightage = totalPrice / totalOriginalPrice;
            const discountForItem = discountAmount * weightage;
            const discountedPrice = totalPrice - discountForItem;

            if (!isNaN(taxAmount) && taxAmount > 0) {
                const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places

                if (totalTax.hasOwnProperty(taxName)) {
                    totalTax[taxName].taxAmount += taxValue;
                    totalTax[taxName].taxPercentage = taxAmount;
                    totalTax[taxName].taxNumber = taxNumber;
                    totalPrices[taxName] += discountedPrice;
                } else {
                    totalTax[taxName] = {
                        taxAmount: taxValue,
                        taxName,
                        taxPercentage: taxAmount,
                        taxNumber
                    };
                    totalPrices[taxName] = discountedPrice;
                }
            }
        });

        const totalTaxArray = Object.entries(totalTax)?.map(([taxName, { taxAmount, taxPercentage, taxNumber }]) => ({
            taxName,
            taxAmount: parseFloat(taxAmount.toFixed(2)), // Ensure rounding to 2 decimal places
            taxNumber,
            taxPercentage,
            totalPrice: parseFloat(totalPrices[taxName].toFixed(2)) // Ensure rounding to 2 decimal places
        }));

        setTaxVal(totalTaxArray);
        console.log(props?.quoteData, totalTaxArray, "Calculated Tax Values");
    };



    const getToken = () => {
        const token = localStorage.getItem('sign')

        return token ? ` ${token}` : ""
    }

    useEffect(() => {

        calc()

    }, [])

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const applyDiscount = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (props?.data?.discountType === '$') {
            discountedTotalValue = parseFloat(props?.data?.discount);
            discountPercentage = (props?.data?.discount / (flattenItems([...props?.data?.items]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2)) * 100)
        }
        else if (props?.data?.discountType === '%') {
            discountedTotalValue = (flattenItems([...props?.data?.items]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2) * (props?.data?.discount / 100));
            discountPercentage = parseFloat(props?.data?.discount);
        }

        return discountedTotalValue.toFixed(2);
    };

    Font.register({
        family: 'Inter',
        fonts: [
            { src: interRegular },

        ]
    })
    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', color: "#3E3E3E" },

        titleContainer: { flexDirection: 'row', marginTop: 24 },

        logo: { width: 70 },

        reportTitle: { fontSize: 16, textAlign: 'center' },

        addressTitle: { fontSize: 11, fontStyle: 'bold' },

        invoice: { fontWeight: 'bold', fontSize: 20, fontStyle: 'Inter', color: '#000' },

        invoiceNumber: { fontSize: 11, fontWeight: 'bold', fontStyle: 'Inter', color: '#000' },

        address: { fontWeight: 400, fontSize: 10 },

        theader: { marginTop: 20, fontSize: 10, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#DEDEDE', borderColor: '#ddd', borderRightWidth: 1, borderBottomWidth: 1 },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: '#ddd', borderRightWidth: 1, borderBottomWidth: 1 },
        tbody3: { fontFamily: 'Inter', fontSize: 10, paddingTop: 4, fontWeight: 'light' },
        tbody4: { fontSize: 10, paddingTop: 4, fontWeight: 'bold' },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: '#ddd', borderBottomWidth: 1 },

        tbody2: { flex: 2, borderRightWidth: 1, },
        container: {
            display: 'flex',

            marginTop: 30,
        },
        divider: {
            borderBottom: '2 solid #000',
            width: 250,
            display: 'flex',
            paddingTop: 60,
            paddingBottom: 5,
            alignItems: 'center',

        },
        dateContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 15,
            marginTop: 5

        },
        dateText: {
            fontSize: 9,
            alignSelf: 'flex-start',
            marginRight: 10,
            fontWeight: 'bold',


        },
        signature: {
            width: 80, // Adjust width as needed
            height: 50, // Adjust height as needed
            position: 'absolute',
            left: 80,
            bottom: 10,
        },
        labelText: {
            fontSize: 9,
            marginRight: 70,
            fontWeight: 'bold',
        },
        bottomMessageContainer: {


            marginTop: 30,

        },
        bottomMessageText: {
            fontSize: 10,
            color: palette.charcoleBlack,
            fontWeight: 'Inter',

        },

    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={[styles.invoice]}>{props?.type === 'Quote' ? 'Quote' : 'Invoice'} </Text>
                    <View style={[styles?.spaceBetween, { justifyContent: 'flex-start' }]}>
                        <Text style={[styles.invoiceNumber, { width: '100px' }]}>{props?.type === 'Quote' ? `Quote number` : `Invoice number`} </Text>
                        <Text style={styles.invoiceNumber}>#{props?.type === 'Quote' ? props?.data?.numberOfQuote : props?.data?.numberOfInvoice} </Text>
                    </View>
                    <View style={[styles?.spaceBetween, { marginTop: '20px',justifyContent: 'flex-start' }]}>
                        <Text style={[styles.invoiceNumber, { width: '100px', fontWeight: '500' }]}>Date of issue </Text>
                        <Text style={styles.invoiceNumber}>{moment(props?.data?.date)?.format('DD MMM YYYY')} </Text>
                    </View>
                    {
                        props?.type === 'Reciept' ? <View style={[styles?.spaceBetween, { marginTop: '20px', justifyContent: 'flex-start' }]}>
                            <Text style={[styles.invoiceNumber, { width: '100px', fontWeight: '500' }]}>Amount </Text>
                            <Text style={styles.invoiceNumber}>{'$0.00'} </Text>
                        </View> : <View />
                    }
                    {
                        taxval?.map((item) => (item?.taxNumber ?
                            <View style={[styles?.spaceBetween, { marginTop: '20px',marginBottom:'25px', justifyContent: 'flex-start' }]}>
                                <Text style={[styles.invoiceNumber, { width: '100px', fontWeight: '500' }]}>{item?.taxName} </Text>
                                <Text style={{ ...styles.invoiceNumber, textAlign: 'left' }}>{item?.taxNumber} </Text>
                            </View> : <View></View>
                        ))
                    }
                    {props?.data?.serviceAddress &&
                        <View style={[styles?.spaceBetween, { marginTop: '20px' }]}>
                            <Text style={[styles.invoiceNumber, { width: '100px', fontWeight: '500' }]}>Service Address</Text>
                            <Text style={{ ...styles.invoiceNumber }}>{props?.data?.serviceAddress} </Text>
                        </View>
                    }
                    {/* <Text style={styles.invoiceNumber}>Date of issue: #{props?.data?.numberOfInvoice} </Text> */}
                </View>
                {/* <Text style={styles.reportTitle}>{props?.userData?.businessName}</Text> */}
                {props?.userData?.logoImage ? <Image style={styles.logo} src={{ uri: `${props?.userData?.logoImage}?not-from-cache-please` }} /> :
                    <View style={[styles.logo, { backgroundColor: palette.newBlue, height: 70, justifyContent: 'center', alignItems: 'center' }]}>
                        <Text style={[styles.invoice, { color: 'white', marginLeft: -5, fontSize: 28 }]}> {getName(props?.userData?.businessName)}</Text>
                    </View>

                }

            </View>
        </View>
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ width: '50%' }}>
                    <Text style={styles.invoice}>{props?.type === 'Quote' ? 'Quote' : 'Invoice'} </Text>
                    <Text style={styles.invoiceNumber}>{props?.type === 'Quote' ? `Quote number: #${props?.data?.numberOfQuote}` : `Invoice number: #${props?.data?.numberOfInvoice}`} </Text>
                </View>
                <View>
                    <Text style={styles.addressTitle}>{props?.userData?.address}</Text>
                    <Text style={styles.addressTitle}>{props?.userData?.email}</Text>

                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={{ ...styles.spaceBetween, justifyContent: 'flex-start' }}>
                <View style={{ width: '55%' }}>
                    <Text style={styles.invoiceNumber}>{props?.userData?.businessName}</Text>


                    <Text style={styles.address}>
                        {props?.userData?.email}
                    </Text>
                    <Text style={styles.address}>
                        {formatMobileNumber(props?.userData?.twilioPhone)}
                    </Text>
                    <Text style={styles.address}>
                        {props?.userData?.address}
                    </Text>
                </View>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.invoiceNumber}>Bill to </Text>
                    <Text style={styles.address}>
                        {props?.data?.name}
                    </Text>

                    <Text style={styles.address}>
                        {props?.data?.email}
                    </Text>
                    <Text style={styles.address}>
                        {formatMobileNumber(props?.data?.customerPhoneNumber)}
                    </Text>
                    <Text style={styles.address}>
                        {props?.data?.customerAddress}
                    </Text>
                </View>
            </View>
        </View>
    );


    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2, { borderLeftWidth: 1 }]}>
                <Text >Service items</Text>
            </View>
            <View style={styles.theader}>
                <Text>Quantity</Text>
            </View>
            <View style={styles.theader}>
                <Text>Unit price</Text>
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>
            </View>
        </View>
    );
    const TableHead1 = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2, { borderLeftWidth: 1 }]}>
                <Text >Material items</Text>
            </View>
            <View style={styles.theader}>
                <Text>Quantity</Text>
            </View>
            <View style={styles.theader}>
                <Text>Unit price</Text>
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>
            </View>
        </View>
    );
    const TableHead2 = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2, { borderLeftWidth: 1 }]}>
                <Text >Items</Text>
            </View>
            <View style={styles.theader}>
                <Text>Quantity</Text>
            </View>
            <View style={styles.theader}>
                <Text>Unit price</Text>
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>
            </View>
        </View>
    );


    const TableBody = () => (
        props?.data?.items?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')?.map((receipt) => (
            <Fragment key={receipt.id}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2, { borderLeftWidth: 1 }]}>
                        {receipt?.picUrl && <Image src={{ uri: `${receipt?.picUrl}?not-from-cache-please` }} style={{ width: '50px', height: '50px', marginBottom: '5px' }} />}
                        <Text >{receipt.name}</Text>
                        <Text style={{ color: 'gray' }} >{receipt.description}</Text>
                    </View>

                    <View style={styles.tbody}>
                        <Text>{receipt?.quantity ? putCommaInvalue(receipt?.quantity) : '1'}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>${receipt?.price ? parseFloat(receipt.price) : '0'} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>${receipt?.price && receipt?.quantity ? putCommaInvalue((receipt?.price * receipt?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(receipt?.price)?.toFixed(2))}</Text>
                    </View>
                </View>
            </Fragment>
        ))
    );
    const TableBody1 = () => (
        props?.data?.items?.filter((item) => item?.type === 'Material' && item?.type !== 'Bundle')?.map((receipt) => (
            <Fragment key={receipt.id}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2, { borderLeftWidth: 1 }]}>
                        {receipt?.picUrl && <Image src={{ uri: `${receipt?.picUrl}?not-from-cache-please` }} style={{ width: '50px', height: '50px', marginBottom: '5px' }} />}
                        <Text >{receipt.name}</Text>
                        <Text style={{ color: 'gray' }} >{receipt.description}</Text>
                    </View>

                    <View style={styles.tbody}>
                        <Text>{receipt?.quantity ? putCommaInvalue(receipt?.quantity) : '1'}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>${receipt?.price ? parseFloat(receipt.price) : '0'} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>${receipt?.price && receipt?.quantity ? putCommaInvalue((receipt?.price * receipt?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(receipt?.price)?.toFixed(2))}</Text>
                    </View>
                </View>
            </Fragment>
        ))
    );
    const TableBody2 = () => (
        props?.data?.items?.filter((item) => item?.type === 'Bundle')?.map((receipt) => (
            <Fragment key={receipt.id}>
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2, { borderLeftWidth: 1 }]}>
                        {receipt?.picUrl && <Image src={{ uri: `${receipt?.picUrl}?not-from-cache-please` }} style={{ width: '50px', height: '50px', marginBottom: '5px' }} />}
                        <Text >{receipt.name}</Text>
                        <Text style={{ color: 'gray' }} >{receipt.description}</Text>
                    </View>

                    <View style={styles.tbody}>
                        <Text></Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text> </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>${receipt?.price && receipt?.quantity ? putCommaInvalue((receipt?.price * receipt?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(receipt?.price)?.toFixed(2))}</Text>
                    </View>
                </View>
                {
                    receipt?.categoryVisiblity !== 'Items are NOT visible to your customer' && receipt?.lineItems?.map((items) =>
                    (
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={[styles.tbody, styles.tbody2, { borderLeftWidth: 1 }]}>
                                {items?.picUrl && <Image src={{ uri: `${items?.picUrl}?not-from-cache-please` }} style={{ width: '50px', height: '50px', marginBottom: '5px' }} />}
                                <Text >{items.name}</Text>
                                <Text style={{ color: 'gray' }} >{items.description}</Text>
                            </View>

                            <View style={styles.tbody}>
                                <Text>{items?.quantity ? putCommaInvalue(items?.quantity) : '1'}</Text>
                            </View>
                            <View style={styles.tbody}>
                                <Text>{receipt?.categoryVisiblity !== 'Items are visible, but item prices are NOT visible to your customer' ? `$${items?.price ? parseFloat(items.price) : '0'}` : ''} </Text>
                            </View>
                            <View style={styles.tbody}>
                                <Text>{receipt?.categoryVisiblity !== 'Items are visible, but item prices are NOT visible to your customer' && `$${items?.price && items?.quantity ? putCommaInvalue((items?.price * items?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(items?.price)?.toFixed(2))}`}</Text>
                            </View>
                        </View>
                    )
                    )
                }
            </Fragment>
        ))
    );

    const TableTotal = () => (
        <View style={{ width: '100%', marginTop: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>

            <View style={{ width: '30%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={[styles.tbody4]}>
                    <Text>Total</Text>
                </View>
                <View style={[styles.tbody4]}>
                    <Text>
                        {`$${putCommaInvalue((flattenItems(props?.data?.items)?.reduce(function (accumulator, currentValue) {
                            if (!currentValue?.optional) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }
                            return accumulator;
                        }, 0) - applyDiscount() + calculateTotalTaxAmount(flattenItems(props?.data?.items), applyDiscount()))?.toFixed(2))}`}

                    </Text>
                </View>
            </View>
        </View>
    );
    const RecipttType = () => (
        <View style={{ width: '100%', marginTop: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>

            <View style={{ width: '30%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={[styles.tbody4]}>
                    <Text>Amount due</Text>
                </View>
                <View style={[styles.tbody4]}>
                    <Text>
                        {`$0.00`}

                    </Text>
                </View>
            </View>
        </View>
    );
    const SubTableTotal = () => (
        <View style={{ width: '100%', marginTop: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <View style={{ width: '30%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.tbody3}>
                    <Text>Subtotal</Text>
                </View>
                <View style={styles.tbody3}>
                    <Text>
                        {`$${putCommaInvalue(flattenItems(props?.data?.items)?.reduce(function (accumulator, currentValue) {
                            if (!currentValue?.optional) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }
                            return accumulator
                        }, 0)?.toFixed(2))}`}

                    </Text>
                </View>
            </View>
        </View>
    );
    const NewBalance = () => (

        <View style={{ width: '100%', marginTop: 5, flexDirection: 'column', alignItems: 'flex-end' }}>
            <View style={{ width: '30%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={[styles.tbody4]}>
                    <Text>Deposit</Text>
                </View>
                <View style={[styles.tbody4]}>
                    <Text>
                        ${props?.data?.deposit}

                    </Text>
                </View>
            </View>
            <View style={{ width: '30%', flexDirection: 'row', marginTop: 5, justifyContent: 'space-between' }}>
                <View style={[styles.tbody4]}>
                    <Text>New balance</Text>
                </View>
                <View style={[styles.tbody4]}>
                    <Text>
                        {`$${putCommaInvalue(((flattenItems(props?.data?.items)?.reduce(function (accumulator, currentValue) {
                            if (!currentValue?.optional) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }
                            return accumulator;
                        }, 0) - applyDiscount() + calculateTotalTaxAmount(flattenItems(props?.data?.items), applyDiscount())) - props?.data?.deposit)?.toFixed(2))}`}

                    </Text>
                </View>
            </View>


        </View>
    );
    const Discount = () => (
        <View style={{ width: '100%', marginTop: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>

            <View style={{ width: '30%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.tbody3}>
                    <Text>Discount</Text>
                </View>
                <View style={styles.tbody3}>
                    <Text>
                        ${applyDiscount()}

                    </Text>
                </View>
            </View>
        </View>
    );
    const TaxTOtal = () => (
        taxval?.map((item) => (
            <View style={{ width: '100%', marginTop: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>
                <View style={{ width: '30%', flexDirection: 'row', justifyContent: 'space-between' }}>


                    <View style={styles.tbody3}>
                        <Text>{item?.taxName} ({item?.taxPercentage}%)</Text>
                        {/* <Text>{item?.taxNumber}</Text> */}
                    </View>
                    <View style={styles.tbody3}>
                        <Text>
                            {`$${putCommaInvalue(parseFloat(item?.taxAmount)?.toFixed(2))}`}
                        </Text>
                    </View>
                </View>
            </View>
        ))
    );

    const BottomMessage = () => (
        <View style={styles.bottomMessageContainer}>
            <Text style={{ fontWeight: '500', fontSize: 16 }}>Message</Text>
            <Text style={styles.bottomMessageText}>{props?.data?.notesInvoice || props?.data?.notesQuote || "hello"}</Text>
        </View>
    );
    const TermAndCondition = () => (
        <View style={styles.bottomMessageContainer}>
            <Text style={{ fontWeight: '500', fontSize: 16 }}>Terms & Conditions</Text>
            <Text style={styles.bottomMessageText}>{props?.userData?.termCondition?.[0]?.invoice || props?.userData?.termCondition?.[0]?.quotes || "hello"}</Text>
        </View>
    );
    return (
        <Document file={props?.file}>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle />
                {/* <Address /> */}
                <UserAddress />
                {props?.type !== 'Quote' && props?.data?.notesInvoice ? <BottomMessage /> : <View></View>}
                {props?.type === 'Quote' && props?.data?.notesQuote ? <BottomMessage /> : <View></View>}
                {props?.data?.items?.filter((item) => item?.type === 'Bundle')?.length > 0 ? <View>
                    <TableHead2 />
                    <TableBody2 /></View> : <View></View>}
                {props?.data?.items?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')?.length > 0 ? <View>
                    <TableHead />
                    <TableBody /></View> : <View></View>}
                {props?.data?.items?.filter((item) => item?.type === 'Material' && item?.type !== 'Bundle')?.length > 0 ? <View> <TableHead1 />
                    <TableBody1 /></View> : <View></View>}

                <SubTableTotal />
                {taxval?.length > 0 && <TaxTOtal />}
                <Discount />
                <TableTotal />
                {props?.type === 'Reciept' ? <RecipttType /> : <View></View>}
                {props?.type !== 'Quote' ? props?.data?.deposit ? <NewBalance /> : <View></View> : <View></View>}
                {props?.data?.attachment?.length > 0 ? <View style={styles.bottomMessageContainer}>
                    <Text style={{ fontWeight: '500', fontSize: 16 }}>Attachments / Photos</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                        {
                            props?.data?.attachment?.map((item) =>
                                <Image src={{ uri: `${item}?not-from-cache-please` }} style={{ width: '120px', height: '120px', marginBottom: '5px', marginRight: '10px' }} />
                            )
                        }
                    </View></View> : <View></View>}
                {props?.type !== 'Quote' && props?.userData?.termCondition?.[0]?.invoice && <TermAndCondition />}
                {props?.type === 'Quote' && props?.userData?.termCondition?.[0]?.quotes && <TermAndCondition />}

                {props?.type === 'Quote' && <View style={styles.container}>
                    <View style={styles.divider}>
                        <Text style={styles.dateText}>{moment(new Date()).format('DD MMM YYYY')}</Text>
                        {getToken() && <Image style={styles.signature} src={getToken()} />}
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={styles.dateContainer}>
                            <Text style={styles.labelText}>Date</Text>
                        </View>
                        <View style={styles.dateContainer}>
                            <Text style={styles.labelText}>Signature</Text>
                        </View>
                    </View>
                </View>}


            </Page>
        </Document>

    )
}

export default NewInvoice