import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import styled from "styled-components";
import { editCreateBookings, getBookings, getCalendarMapDataV2 } from "../../store/Actions/dashboard.action";
import { useDispatch } from "react-redux";
import mapStyles from "../../Components/Tracking/mapStyles";
import RightModal from "../../Components/RightSideModal";
import timeIcon from '../../assets/clock.png'
import calander from '../../assets/CalendarPic.png'
import calanderIcon from '../../assets/calenda.png'
import Modals from '../../Components/Modal';
import Calendar from "react-calendar";
import cross from '../../assets/cross.png'
import placeholder from '../../assets/gps.png'
import telephone from '../../assets/viber.png'
import notebook from '../../assets/contactbook.png'
import moment from "moment";
import { colorThemes, formatMobileNumber, putCommaInvalue, TimeListAm, TimeListPM } from "../../utils/Commonfunction";
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { sendMessage } from "../../store/Actions/Auth.action";
const filter = createFilterOptions();
function Map(props) {
    const [activeMarker, setActiveMarker] = useState(null);
    const [loader, setLoader] = useState(false)
    const [tabData, setTabData] = useState([])
    const [values, setValues] = useState({})
    const [notes, setNotes] = useState('')
    const [isendModalopen, setIsemdModalOpen] = useState(false)
    const [isstartModalopen, setIsstartModalOpen] = useState(false)
    const [team, setTeam] = React.useState(props?.team || [])
    const [selectedTeam, setSelectedTeam] = React.useState(props?.value?.team || [])

    const [selectedItem, setSelectedItem] = useState({})
    const [isOpenTask, setIsModalTask] = useState(false)
    const [value3] = React.useState(null);
    const [editValue, setEditValue] = useState({
        "name": props?.value?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || "",
        "to": props?.value?.number || "",
        "businessName": props?.value?.businessName || ''

    })
    const dispatch = useDispatch()

    const init = async () => {
        setLoader(true);
        let res = await dispatch(getCalendarMapDataV2());
        setTeam(res?.data?.team)
        if (res.status === true) {
            const data = [...res?.data?.detailData]?.filter(items => !items?.date && items?.customerAddress);
            const promises =
                await Promise.all(
                    data.map((item, index) => {
                        if (item?.latitude && item?.longitude) {
                            return {
                                name: item?.customerAddress,
                                position: { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                                item: item
                            };
                        }
                        return null;
                    })
                )
            const filteredData = promises.filter(item => item !== null);
            // const promises = data.map((item, index) => {

            //     return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${item?.customerAddress}&key=AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA`)
            //         .then(response => response.json())
            //         .then(data => {
            //             if (data?.results && data?.results?.length > 0) {
            //                 console.log('location', data.results[0].geometry.location)
            //                 const location = data.results[0].geometry.location;
            //                 const jitteredLatitude = location.lat + (index * 0.0001);
            //                 const jitteredLongitude = location.lng + (index * 0.0001);
            //                 return {
            //                     name: item?.customerAddress,
            //                     position: { lat: jitteredLatitude, lng: jitteredLongitude },
            //                     item: item
            //                 };
            //             }
            //             else {
            //                 console.warn(`No results found for address: ${item?.customerAddress}`);
            //                 return {
            //                     name: item?.customerAddress,
            //                     position: { lat: 0, lng: 0 },
            //                     item: item
            //                 }
            //             }
            //         });

            // });
            setTabData(filteredData)
            setLoader(false);
         
        }
    };


    useEffect(() => {
        init()
    }, [])

    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        tabData?.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    };

    const onPress = async () => {
        var newDate = values?.time ? new Date() : '';
        var newDate1 = values?.endTime ? new Date() : '';
        const selectedData = []

        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (values?.time) {

            const val = values?.time?.split(':');
            const val2 = val[1].split(' ')
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }
        if (values?.endTime) {

            const val3 = values?.endTime?.split(':');
            const val4 = val3[1].split(' ')
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }


        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        await dispatch(editCreateBookings({ ...selectedItem?.item, ...values, "notes": notes, ...editValue, phone: editValue?.to, time: newDate, endTime: newDate1, "referenceNo": selectedItem?.item?.referenceNo, "workersAssigned": selectedData }))
        setIsModalTask(false)
        await dispatch(sendMessage(
            {
                "to": editValue?.to,
                "from": `+${localStorage.getItem('number')}`,
                "body": `Booking confirmed! It’s scheduled for ${moment(values?.date).format('DD MMM YYYY') !== 'Invalid date' ? moment(values?.date).format('DD MMM YYYY') : values?.date} at ${values?.time}.`
            }
        ))
        init()
        if (props?.noheader) {
            props?.onSubmit()
        }

    }


    return (
        loader ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
            src={require('../../assets/loaders.json')}
            className="player"
            style={{ width: '200px', height: '200px' }}
            loop
            autoplay
        />
        </div> :
            <Body style={{ ...props?.customStyle }}>
                {!props?.noheader && <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <CloseButton onClick={() => props?.setModal(false)}>
                        <Icons src={cross} />
                    </CloseButton>
                    <h3>
                        {"Unscheduled jobs"}
                    </h3>
                </div>}
                <div style={{ display: 'flex', overflowY: 'scroll' }}>
                    <RightSide>
                        {tabData?.length > 0 ? tabData.map((item) => <StyleBox style={{ cursor: 'pointer' }} onClick={() => {
                            setEditValue({
                                name: item?.item?.name,
                                to: item?.item?.customerNumber,
                                email: item?.item?.email,
                                address: item?.item?.customerAddress
                            })
                            setSelectedItem(item)
                            setIsModalTask(true)
                            setSelectedTeam([])
                            setValues({})
                            setNotes('')
                        }}>


                            <div className='valueContainer' style={{ flexDirection: 'column', alignItems: 'start', borderRadius: '8px' }}>
                                <FlexDetail>
                                    <ValueName> {item?.item?.name}</ValueName>
                                    <MoreIcon style={{ width: '15px' }} src={notebook} />
                                </FlexDetail>
                                <FlexDetail>
                                    <ValueName>{formatMobileNumber(item?.item?.customerNumber)}</ValueName>
                                    <MoreIcon style={{ width: '15px' }} src={telephone} />
                                </FlexDetail>
                                {item?.item?.customerAddress && <FlexDetail> <ValueName>{item?.item?.customerAddress}</ValueName>
                                    <MoreIcon style={{ width: '12px', marginRight: '3px' }} src={placeholder} />
                                </FlexDetail>}

                            </div>

                        </StyleBox>)
                            :
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                                <h4>No Unscheduled jobs</h4>
                            </div>
                        }

                    </RightSide>
                    {tabData?.length > 0 ? <GoogleMap
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{ width: "100vw", height: "100vh" }}
                        options={{
                            styles: mapStyles // Apply custom map styles
                        }}
                        center={selectedItem?.position && selectedItem?.position}
                        zoom={selectedItem?.position ? 16 : 4}
                    >


                        {tabData?.map((item, index) => (

                            <MarkerF
                                key={index}
                                position={item?.position}
                                onClick={() => {
                                    setEditValue({
                                        name: item?.item?.name,
                                        to: item?.item?.customerNumber,
                                        email: item?.item?.email,
                                        address: item?.item?.customerAddress
                                    })
                                    setValues({})
                                    setSelectedItem(item)
                                    setSelectedTeam([])
                                    setNotes('')
                                    setIsModalTask(true)
                                }}
                            >
                                {activeMarker === index ? (
                                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                        <div>{item?.name}</div>
                                    </InfoWindow>
                                ) : null}
                            </MarkerF>
                        ))}
                    </GoogleMap> : <GoogleMap
                        zoom={4}
                        mapContainerStyle={{ width: "100vw", height: "100vh" }}
                        options={{
                            styles: mapStyles // Apply custom map styles
                        }}

                        center={{ lat: 1.10, lng: 1.10 }}

                    >
                    </GoogleMap>}
                </div>
                {isOpenTask && <RightModal onClose={() => setIsModalTask(false)} customStyle={{ width: '30%' }} isOpen={isOpenTask}>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => setIsModalTask(false)}>
                                <Icons src={cross} />
                            </CloseButton>
                            <h4>Schedule job</h4>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <StyleBox>
                                <div className='valueContainer' style={{ flexDirection: 'column', alignItems: 'start', borderRadius: '8px' }}>
                                    <FlexDetail>
                                        <ValueName> {editValue?.name}</ValueName>
                                        <MoreIcon style={{ width: '15px' }} src={notebook} />
                                    </FlexDetail>
                                    <FlexDetail>
                                        <ValueName>{editValue?.to}</ValueName>
                                        <MoreIcon style={{ width: '15px' }} src={telephone} />
                                    </FlexDetail>

                                    {/* {editValue?.email && <FlexDetail> <ValueName>{editValue?.email}</ValueName>
                                    <MoreIcon style={{ width: '20px' }} src={email} />
                                </FlexDetail>} */}
                                    {editValue?.address && <FlexDetail> <ValueName>{editValue?.address}</ValueName>
                                        <MoreIcon style={{ width: '12px', marginRight: '3px' }} src={placeholder} />
                                    </FlexDetail>}

                                </div>
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <LeftHeader>{"Schedule"}</LeftHeader>

                                    <MoreIcon style={{ width: '22px' }} src={calander} alt={calander} />

                                </div>
                                <div className='valueContainer'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ width: '30px', textAlign: 'start', fontWeight: '500' }}>Start</p>
                                        <DateButton onClick={() => setIsstartModalOpen(true)}>
                                            {values?.date ? moment(values?.date).format('DD MMM YYYY') : 'Date'}

                                            <MoreIcon style={{ width: '15px' }} src={calanderIcon} alt={calanderIcon} />
                                        </DateButton>
                                        <DateButton onClick={() => setIsstartModalOpen(true)}>
                                            {values?.time || "Time"}
                                            <MoreIcon style={{ width: '15px' }} src={timeIcon} alt={timeIcon} />
                                        </DateButton>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <p style={{ width: '30px', textAlign: 'start', fontWeight: '500' }}>End</p>
                                        <DateButton onClick={() => setIsemdModalOpen(true)}>
                                            {values?.endDate ? moment(values?.endDate).format('DD MMM YYYY') : 'Date'}
                                            <MoreIcon style={{ width: '15px' }} src={calanderIcon} alt={calanderIcon} />
                                        </DateButton>
                                        <DateButton onClick={() => setIsemdModalOpen(true)}>
                                            {values?.endTime || "Time"}
                                            <MoreIcon style={{ width: '15px' }} src={timeIcon} alt={timeIcon} />
                                        </DateButton>
                                    </div>

                                </div>
                            </StyleBox>
                            <StyleBox >
                                <div style={{ padding: '10px 15px 10px 15px' }}>
                                    <h4 style={{ marginTop: '5px', marginBottom: '5px', textAlign: 'start' }}>Line items</h4>
                                    {
                                        selectedItem?.item?.items?.map(item =>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                                                <div style={{ display: 'flex', alignItems: 'start' }}>

                                                    <div>
                                                        <p style={{ fontSize: '14px', textAlign: 'start', }}>{item?.name} × {item?.quantity || '1'}</p>
                                                        <p style={{ fontSize: '14px', textAlign: 'start', color: 'gray', marginTop: '-5px' }}>{item?.description}</p>
                                                    </div>
                                                </div>
                                                <p style={{ fontSize: '14px' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                            </div>

                                        )
                                    }
                                </div>
                            </StyleBox>
                            <StyleBox>
                                <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                    <Autocomplete1
                                        value={value3}
                                        onChange={(event, newValue) => {
                                            console.log(newValue, "dfkvndkkjdvkjdvfjkn")
                                            const searchString = 'Add new contact';
                                            console.log(newValue, 'dsckjnl')
                                            if (newValue && newValue?.firstName?.includes(searchString)) {

                                            }

                                            else if (typeof newValue?.firstName === 'string') {
                                                //setValue2(result);
                                                setSelectedTeam([...selectedTeam, newValue])

                                                // setSelectedHash([...selectHash, result])
                                            }
                                            else {
                                                //setValue2(result)
                                                setSelectedTeam([...selectedTeam, newValue])
                                                //  setHasTag([...selectHash,result])
                                            }

                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option?.firstName);

                                            // filtered.push({ name: `Add new contact` });


                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="free-solo-with-text-demo"
                                        options={team}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option?.firstName === 'string') {
                                                return option.firstName;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option?.firstName}</li>}
                                        sx={{
                                            width: 320, '& input': {
                                                height: '5px', // Define the height of the input box here
                                            },
                                        }}
                                        freeSolo
                                        style={{ height: 30, }}
                                        renderInput={(params) => (
                                            <div>
                                                <TextField style={{ fontSize: '14px' }} {...params}


                                                    placeholder="Add crew member" />

                                            </div>
                                        )}
                                    />
                                    {selectedTeam?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '30px', marginLeft: '12px', width: '295px' }}>
                                        {selectedTeam?.map((item, index) =>
                                            <div style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}>{item?.firstName} <Icons style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                const arr = selectedTeam?.filter(obj => obj?.email !== item?.email)
                                                setSelectedTeam(arr)
                                            }} src={cross} /></div>
                                        )}
                                    </div> : null}

                                </div>
                            </StyleBox>
                            <div className='valueContainer' style={{ flexDirection: 'column' }}>
                                {/* <Label htmlFor={'Email'}>Description</Label> */}
                                <InputText
                                    placeholder="Note"
                                    type="text"
                                    value={notes}
                                    // value={editValue?.email}

                                    onChange={(e) => {
                                        setNotes(
                                            e.target.value
                                        )
                                    }}

                                />
                            </div>

                        </div>
                        {<div style={{ alignSelf: 'end', marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%', paddingBottom: '20px', }}>
                            <SendButton style={{ background: '#DDD', color: '#000', marginRight: '20px' }}>Cancel</SendButton>
                            <SendButton onClick={() => onPress()} style={{ marginRight: '40px', }} >Save</SendButton>
                        </div>}
                    </div>
                </RightModal>}
                {isendModalopen && <Modals

                    onClose={() => setIsemdModalOpen(false)}
                    customStyle={{ background: '#fff' }}
                >
                    <div style={{ marginLeft: '-10px' }}>
                        <HeaderContainer>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CloseButton onClick={() => setIsemdModalOpen(false)}>
                                    <Icons src={cross} />
                                </CloseButton>
                                <h4>Select end date & time</h4>
                            </div>
                        </HeaderContainer>
                        <CalendarStyle style={{ zIndex: 10000, }} className='p-3 d-flex justify-content-center'>
                            <div style={{ zIndex: 10000, borderRight: '1px solid #f3f3f3', marginRight: '15px', width: '350px', paddingRight: '-25px' }} >
                                <Calendar className='react-calendar'
                                    value={values?.endDate}
                                    onChange={(val) => setValues({ ...values, endDate: val })}
                                    minDate={new Date()}
                                />

                            </div>
                            <Flexbox className='justify-content-between'>
                                <div style={{ display: 'inline', marginTop: '10px', marginRight: 10, }}>
                                    {TimeListAm.map((item) => {
                                        return (
                                            <div onClick={() => setValues({ ...values, endTime: item })} style={{ padding: '5px', background: values?.endTime === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.endTime !== item ? '#000' : '#fff', fontSize: '14px', border: values?.endTime === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>
                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{ display: 'inline', marginTop: '10px' }}>
                                    {TimeListPM.map((item) => {
                                        return (
                                            <div onClick={() => setValues({ ...values, endTime: item })} style={{ padding: '5px', background: values?.endTime === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.endTime !== item ? '#000' : '#fff', border: values?.endTime === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', fontSize: '14px', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>
                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Flexbox>
                        </CalendarStyle>
                        <ButtonBox>
                            <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => setIsemdModalOpen(false)}>Cancel</SendButton1>
                            <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                setIsemdModalOpen(false)
                            }}>Save</SendButton1>
                        </ButtonBox>
                    </div>
                </Modals>}
                {isstartModalopen && <Modals

                    onClose={() => setIsstartModalOpen(false)}
                    customStyle={{ background: '#fff' }}
                >
                    <div style={{ marginLeft: '-10px' }}>
                        <HeaderContainer>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CloseButton onClick={() => setIsstartModalOpen(false)}>
                                    <Icons src={cross} />
                                </CloseButton>
                                <h4>Select start date & time</h4>
                            </div>
                        </HeaderContainer>
                        <CalendarStyle style={{ zIndex: 10000, }} className='p-3 d-flex justify-content-center'>
                            <div style={{ zIndex: 10000, borderRight: '1px solid #f3f3f3', marginRight: '15px', width: '350px', paddingRight: '-25px' }} >
                                <Calendar className='react-calendar'
                                    value={values?.date}
                                    onChange={(val) => setValues({ ...values, date: val })}
                                    minDate={new Date()}
                                />

                            </div>
                            <Flexbox className='justify-content-between'>
                                <div style={{ display: 'inline', marginTop: '10px', marginRight: 10, }}>
                                    {TimeListAm.map((item) => {
                                        return (
                                            <div onClick={() => setValues({ ...values, time: item })} style={{ padding: '5px', background: values?.time === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.time !== item ? '#000' : '#fff', fontSize: '14px', border: values?.time === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>
                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{ display: 'inline', marginTop: '10px' }}>
                                    {TimeListPM.map((item) => {
                                        return (
                                            <div onClick={() => setValues({ ...values, time: item })} style={{ padding: '5px', background: values?.time === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.time !== item ? '#000' : '#fff', border: values?.time === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', fontSize: '14px', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>

                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Flexbox>
                        </CalendarStyle>
                        <ButtonBox>
                            <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => setIsstartModalOpen(false)}>Cancel</SendButton1>
                            <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                setIsstartModalOpen(false)
                                // const res = await dispatch(createNewCustomField({
                                //     "fields": [
                                //         newCutomField
                                //     ]
                                // }))
                                // setDataCustomField([...customField, newCutomField])
                                // setIsModalOpen1(false)
                            }}>Save</SendButton1>
                        </ButtonBox>
                    </div>
                </Modals>}


            </Body>
    );
}

export default Map;
const Body = styled.div`
display:flex;
flex-direction:column;
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;

`
const RightSide = styled.div`
width:390px;
display:flex;
flex-direction:column;
align-items:center;
padding-top:15px;
flex-shrink: 0;
background-color: #DDDDD;
overflow-y:scroll;
@media (min-width: 260px) and (max-width: 1311px){
    display:none;
}
`
const MoreIcon = styled.img`
width:15px;
cursor:pointer;
color:black
`
const CalendarStyle = styled.div`
display:flex;
box-sizing: border-box;
background: #FFFFFF;

height:auto;
border-radius: 5px;
padding:15px;
margin-left:15px;
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 14px;
font-weight:500;
padding-left:25px;
padding-right:25px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;

cursor:pointer;

`
const HeaderContainer = styled.div`
display:flex;
background:#fff;
padding-left:25px;
padding-right:25px;
padding-top:5px;
padding-bottom:5px;
height:auto;
justify-content:space-between;
align-items:center; 
`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid #f3f3f3;
background:white;
width:350px;
margin-top:20px;
border-radius:8px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);

.keyContainer{
    padding: 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding: 14px;
    background:#fff;
    font-size:14px;
display:flex;
flex-direction:column;
border-radius:8px;
}
`
const LeftHeader = styled.p`
color:#000;
font-size: 16px;
text-align:start;
margin-top:0px;
margin-bottom:0px;
width:70%;
font-weight:600;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const DateButton = styled.div`
display:flex;
align-items:center;
margin-left:15px;
cursor:pointer;
height:35px;
width:125px;
border-radius:8px;
padding-left:10px;
padding-right:10px;
background:#F3F3F3;
font-sizze:14px;
justify-content:space-between;


`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const Icons = styled.img`
width: 15px;
height: 15px;
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Flexbox = styled.div`
display:flex;
height:auto;
`
const FlexDetail = styled.div`
width:100%;
margin-top:-10px;
display:flex;
justify-content:space-between;
align-items:center;

`
const ValueName = styled.p`
color:#000;

font-size: 14px;
text-align:start;

width:100%;
font-weight:500;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; /* Set Inter font family */
  height: 60px; /* Normal height of two lines */
  font-size: 14px;
  padding: 10px;
  line-height: 1.5; /* Adjust line height as needed */
  word-wrap: break-word;
  width: 330px;
  margin-top:20px;
  display: block;
  border-radius: 5px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #fff;
  resize: vertical; /* Allow vertical resizing */
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
