import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowDropDown } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import { useDispatch } from "react-redux";
import { submitEditVisit } from "../../../store/Actions/JobAction";
import { dayViewColor } from "../../../utils/Commonfunction";

const Card = styled.div`
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  font-size:14px;
  padding:15px 0px;
  border-bottom:1px solid #eee;

`;
const ShowMore = styled.div`
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #0073e6;
  font-weight:550;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const VisitInfo = styled.div`

  display: flex;
  align-items: start;
  flex-direction: column;
  min-width: 0; 
`;

const VisitTitle = styled.p`
  color: #0f52ba;
  font-weight: bold;
  margin: 0;
  margin-bottom: 8px;
  font-size:13px;
`;

const VisitDate = styled.p`
font-size:13px;
  margin: 0;
`;

const VisitDescription = styled.p`
  color: #757575;
  margin: 5px 0 0 0;
  text-align:start;
  font-size:13px;
`;

const StatusSelectWrapper = styled.div`
  position: relative;
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height:20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;
const DropdownIconWrapper = styled.div`
  position: absolute;
  right: 2px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusSelect = styled.select`
  appearance: none;
  background-color: #0f52ba;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 7px 40px 7px 16px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #0d3f88;
  }

  option {
    background-color: white;
    color: black;
    font-weight: 500;
  }
`;
const AvatarGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size:13px;
  margin-top:5px;
`;
const Loader = styled.div`
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const SiteVisit = ({ visits, referenceNo, onSubmit,onClickEdit }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        setExpanded(!expanded);
    };
    const itemsToShow = expanded ? visits?.length : 2;
    const getName = (channel) => {
        if (!channel) {
            return;
        }
        const trimmedChannel = channel.trim();
        const val = trimmedChannel.split(' ');

        if (val.length === 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
        } else {
            return `${val[0]?.charAt(0).toUpperCase()}`;
        }
    };
    return (
        <div>
            {visits?.slice(0, itemsToShow)?.map((visit, index) => (
                <Card key={index}>
                    <VisitInfo style={{ width: '70%' }}>
                        <VisitTitle>{visit?.title||`Visit ${index+2}`}</VisitTitle>
                        <VisitDate> {`${moment(visit?.date).format("DD MMM")}, ${moment(visit?.time).format("hh:mm A")} - ${moment(visit?.endDate).format("DD MMM")}, ${moment(visit?.endTime).format("hh:mm A")}`}</VisitDate>
                        <VisitDescription>{visit?.description}</VisitDescription>
                    </VisitInfo>
                    <VisitInfo>
                        <div style={{ display: 'flex' }}>

                            <StatusSelectWrapper>
                                {<>
                                    <StatusSelect style={{ background: visit?.status === 'Waiting for confirmation' ? '#045ad0' : visit?.status === 'In Progress' ? '#de8c4e' : visit?.status === 'Completed' ? '#004D4D' :visit?.status ==='Canceled'?'#8B0000': '#7B8089' }} defaultValue={visit?.status === 'Waiting for confirmation' ? 'Open' : visit?.status}

                                        onChange={async (event) => {
                                            setLoading(true);
                                            await dispatch(submitEditVisit({ "status": event.target.value }, referenceNo, visit?._id))
                                            onSubmit()
                                            setLoading(false);

                                        }}>
                                        <option value="Open">Open</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Completed">Completed</option>
                                        <option value="cancelled">cancelled</option>
                                    </StatusSelect>
                                    <DropdownIconWrapper>
                                        <ArrowDropDown style={{ color: "white" }} />
                                    </DropdownIconWrapper>
                                </>}
                            </StatusSelectWrapper>

                            <IconButton onClick={()=>onClickEdit(visit)}>
                                <EditIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                        </div>
                        <AvatarGroup>
                            {visit?.worker?.map((person, idx) => (
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: '#fff',
                                                color: '#000',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                                                border: '1px solid #ddd',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                padding: '8px'

                                            },
                                        },
                                        arrow: {
                                            sx: {
                                                color: '#fff',
                                            },
                                        },
                                    }}
                                    arrow title={<div style={{ background: 'white', color: 'black', width: '100%' }}>{person?.firstName}</div>} placement='top'>
                                    <Avatar key={idx} style={{ backgroundColor: dayViewColor[idx]?.light,color:dayViewColor[idx]?.original, marginRight: "4px", width: '30px', height: '30px', fontSize: '13px',fontWeight:'500' }}>
                                        {getName(person?.firstName)}
                                    </Avatar>
                                </Tooltip>
                            ))}
                        </AvatarGroup>


                    </VisitInfo>
                </Card>
            ))}

            {visits?.length > 2 && (
                <ShowMore onClick={toggleExpand}>
                    {expanded ? "Show less" : `See ${visits?.length - 2} more visit(s)`}
                    <IconButton>
                        <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0)' }} />
                    </IconButton>
                </ShowMore>
            )}

        </div>
    );
};

// PropTypes for type checking
SiteVisit.propTypes = {
    visits: PropTypes.array
};

export default SiteVisit;
