import logo from '../assets/notifilogo.png'
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData, getQuotedata } from '../store/Actions/Auth.action';
import { taxprice, totalprice, formatMobileNumber, calculateTotalTaxAmount } from '../utils/Commonfunction';
import moment from 'moment';
import { palette } from '../utils/Palette';
const InvoiceReport = (props) => {
    let location = useLocation()

    let { id } = useParams()
    //const windowUrl = window.location.search;
    //const params = new URLSearchParams(windowUrl);
    const searchParams = new URLSearchParams(location.search);
    const userData = useSelector(state => state?.auth?.userNameData)
    const [taxval, setTaxVal] = useState(props?.taxval || [])
    const referenceNo = searchParams.get('referenceNo');
    const email = searchParams.get('email');
    //let quote = location.pathname
    //let refnumber = quote?.[3]
    // console.log(windowUrl,"num")
    let dispatch = useDispatch()
    const flattenItems = (items) => {
        return items?.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };
    const calc = () => {
        const totalTax = {};
        const totalPrices = {}; // To accumulate the total prices for each tax
        const discountAmount = parseFloat(applyDiscount()) || 0;
        const items = flattenItems([...quotedata?.items]);

        // Step 1: Calculate the total price of all items
        const totalOriginalPrice = items.reduce((acc, item) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);
            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return acc;
            }
            return acc + (price * quantity);
        }, 0);

        // Step 2: Calculate the weightage and apply the discount accordingly
        items.forEach(item => {
            const taxName = item.taxName;
            const taxAmount = parseFloat(item.tax);
            const taxNumber = item?.taxNumber || '';
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);

            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return;
            }

            const totalPrice = price * quantity;
            const weightage = totalPrice / totalOriginalPrice;
            const discountForItem = discountAmount * weightage;
            const discountedPrice = totalPrice - discountForItem;

            if (!isNaN(taxAmount) && taxAmount > 0) {
                const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places

                if (totalTax.hasOwnProperty(taxName)) {
                    totalTax[taxName].taxAmount += taxValue;
                    totalTax[taxName].taxPercentage = taxAmount;
                    totalTax[taxName].taxNumber = taxNumber;
                    totalPrices[taxName] += discountedPrice;
                } else {
                    totalTax[taxName] = {
                        taxAmount: taxValue,
                        taxName,
                        taxPercentage: taxAmount,
                        taxNumber
                    };
                    totalPrices[taxName] = discountedPrice;
                }
            }
        });

        const totalTaxArray = Object.entries(totalTax)?.map(([taxName, { taxAmount, taxPercentage, taxNumber }]) => ({
            taxName,
            taxAmount: parseFloat(taxAmount.toFixed(2)), // Ensure rounding to 2 decimal places
            taxNumber,
            taxPercentage,
            totalPrice: parseFloat(totalPrices[taxName].toFixed(2)) // Ensure rounding to 2 decimal places
        }));

        setTaxVal(totalTaxArray);
        console.log(totalTaxArray, "Tax Values");
    };
    const init = async () => {
        await dispatch(getInvoiceData({ referenceNo: id }))
        calc()
    }
    useEffect(() => {
        init()
    }, [])
    const quotedata = useSelector(state => state?.auth?.invoice)
    console.log(quotedata, 'quotedata')
    const styles = {
        page: {
            marginLeft: '3rem',
            marginRight: '3rem',
            'page-break-after': 'always',

        },

        columnLayout: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '2rem 0 2rem 0',
            marginBottom: '50px',
            gap: '2rem',
        },

        column: {
            display: 'flex',
            flexDirection: 'column',
        },

        spacer2: {
            height: '2rem',
        },

        fullWidth: {
            //width: '100%',
            marginTop: '20px',
            height: '40px'
        },

        marginb0: {
            marginBottom: 0,
        },
        text: {
            fontSize: 14,
            fontWeight: '500',

        },
        text2: {
            fontSize: 14,
            textAlign: 'start',
            fontWeight: '500',
            marginBottom: 3,
            marginTop: 5,
            width: '150px',


        }
        , text1: {
            marginTop: '-10px',
            textAlign: 'start',
            fontSize: 14,
            fontWeight: '500',
            color: palette?.charcoleBlack

        }
    };
    const putCommaInvalue = (price) => {

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formatted;
    }
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const applyDiscount = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (quotedata?.discountType === '$') {
            discountedTotalValue = parseFloat(quotedata?.discount);
            discountPercentage = (quotedata?.discount / (flattenItems([...quotedata?.items])?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2)) * 100)
        }
        else if (quotedata?.discountType === '%') {
            discountedTotalValue = (flattenItems([...quotedata?.items])?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2) * (quotedata?.discount / 100));
            discountPercentage = parseFloat(quotedata?.discount);
        }

        return discountedTotalValue.toFixed(2);
    };

    return (
        <Body>
            <MainContainer>
                <div style={styles.page}>
                    <div style={{ ...styles.columnLayout, justifyContent: 'space-between' }}>
                        <div style={{ width: '100%' }}>
                            <h1 style={{ marginTop: '0px', marginLeft: '-3px', marginBottom: '15px', fontWeight: '600', fontSize: 28, textAlign: 'start', }}>
                                {"Invoice"}
                            </h1>
                            <div style={{ display: 'flex' }}>
                                <p style={styles.text2}>{"Invoice number:"}</p>
                                <p style={styles.text2}>#{quotedata?.numberOfInvoice}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <p style={styles.text2}>{"Date of Issue:"}</p>
                                <p style={styles.text2}>{moment(quotedata?.date).format('MMMM DD, YYYY')}</p>
                            </div>
                            {taxval?.map((item) => (item?.taxNumber &&
                                <div style={{ display: 'flex' }}>
                                    <p style={styles.text2}>{item?.taxName}</p>
                                    <p style={styles.text2}>{item?.taxNumber}</p>
                                </div>
                            ))}
                            {quotedata?.serviceAddress && <div style={{ display: 'flex' }}>
                                <p style={styles.text2}>{"Service Address"}</p>
                                <p style={{ ...styles.text2, width: '50%' }}>{quotedata?.serviceAddress}</p>
                            </div>}

                        </div>
                        {<div style={{ display: 'flex', alignItems: 'start' }}>
                            {userData?.logoImage ? <ProfilePic src={userData?.logoImage} /> : <OutProfile>
                                {getName(userData?.businessName)}</OutProfile>}

                        </div>}
                        <div >
                            {/* <h1 style={{ marginBottom: '10px', textAlign: 'start', }}>
                            {"Invoice"}
                        </h1> */}
                            {/* <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Invoice number"}</p>
                            <p style={styles.text2}>#{quotedata?.numberOfInvoice}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <p style={styles.text2}>{"Date of Issue"}</p>
                            <p style={styles.text2}>{moment(quotedata?.date).format('MMMM DD, YYYY')}</p>
                        </div> */}
                            {/* <div style={{ display: 'flex',flexDirection:'column' }}>

                            <p style={{ ...styles.text2,width:'auto', fontSize: '20px',marginBottom:'15px' }}>{userData?.businessName}</p>
                            
                             <p style={styles.text1}>{'#1104 - 8570 Ruvergrass Dr., Vancouver, BC. V5S 0H4 '} </p>
                        <p style={styles.text1}>{userData?.email} </p>
                        </div> */}
                        </div>


                    </div>


                    <Container style={{ justifyContent: 'space-between' }} >

                        <div className='detailCont'>
                            <h4 style={{ fontSize: '14px', marginBottom: '10px' }}>{userData?.businessName}</h4>

                            {userData?.email && <p style={styles.text}>{userData?.email} </p>}
                            <p style={styles.text1}>{userData?.twilioPhone ? formatMobileNumber(userData?.twilioPhone) : ''}</p>
                            <p style={styles.text1}>{userData?.address} </p>

                        </div>
                        <div className='detailCont'>
                            <h4 style={{ fontSize: '14px', textAlign: 'start', marginBottom: '10px' }}>Bill to</h4>
                            {quotedata?.name ? <p style={styles.text}>{quotedata?.name ? quotedata?.name : '-'}</p> : null}
                            {quotedata?.email ? <p style={styles.text1}>{quotedata?.email ? quotedata?.email : '-'}</p> : null}
                            {quotedata?.phone ? <p style={styles.text1}>{quotedata?.phone ? formatMobileNumber(quotedata?.customerPhoneNumber) : '-'}</p> : null}
                            {quotedata?.customerAddress ? <p style={styles.text1}>{quotedata?.customerAddress ? quotedata?.customerAddress : '-'}</p> : null}
                            {/* <p style={styles.text1}> Delaware 19709United States</p> */}

                        </div>

                        {/* <div className='detailCont' style={{display:'flex', justifyContent:'flex-end'}}>
                  <h3 style={{ fontSize: 16,marginTop:0 }}>Issued {quotedata?.date}</h3>
               
                      </div> */}
                    </Container>
                    {/* <img style={styles.fullWidth} src="photo-2.png" /> */}
                </div>

                <div style={styles.page}>
                    {/* <div>
                    <h3 style={{ textAlign: 'center' }}>Thank you for your business! </h3>
                </div> */}

                    <div >
                        {quotedata?.notesInvoice && <BottomMessageContainer>
                            <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Message</h3>
                            <BottomMessageText>{quotedata?.notesInvoice}</BottomMessageText>
                        </BottomMessageContainer>}
                        {quotedata?.items?.filter((item) => item?.type === 'Bundle')?.length > 0 && <Table style={{ marginBottom: '20px' }}>
                            <tr style={{ backgroundColor: '#fff', background: '#DEDEDE' }}>
                                {/* <td>Name</td>
                        <td></td> */}
                                <td style={{ fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Items"}</td>


                                {/* <td></td> */}
                                {/* <td></td>
                            <td></td> */}
                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Quantity"}</td>



                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Unit price"}</td>




                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Amount"}</td>

                            </tr>
                            {quotedata?.items?.filter((item) => item?.type === 'Bundle')?.map((x, index) => {
                                return (x?.optional ? null : <>

                                    <tr style={{ backgroundColor: '#fff' }}>
                                        {/* <td>{x?.name ? x?.name : '-'}</td>
        <td></td> */}

                                        <td><div>
                                            {x?.picUrl && <ItemImg src={x?.picUrl} />}
                                            <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {x?.name ? `${x?.name}` : '-'}</p>
                                            {x?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{x?.description ? `${x?.description}` : '-'}</p>}
                                        </div>
                                        </td>
                                        <td style={{ maxWidth: '100px' }}></td>


                                        <td ></td>




                                        <td>${x?.price && x?.quantity ? putCommaInvalue((x?.price * x?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(x?.price)?.toFixed(2))}</td>


                                    </tr>
                                    {x?.categoryVisiblity !== 'Items are NOT visible to your customer' &&
                                        x?.lineItems?.map((item, index) =>
                                            <tr style={{ backgroundColor: '#fff' }}>
                                                {/* <td>{x?.name ? x?.name : '-'}</td>
        <td></td> */}

                                                <td><div>
                                                    {item?.picUrl && <ItemImg src={item?.picUrl} />}
                                                    <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {item?.name ? `${item?.name}` : '-'}</p>
                                                    {item?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{item?.description ? `${item?.description}` : '-'}</p>}
                                                </div>
                                                </td>
                                                <td >
                                                    <div style={{ fontWeight: '500' }}>
                                                        {item?.quantity ? putCommaInvalue(item?.quantity) : '1'}
                                                    </div>
                                                </td>
                                                <td >
                                                    {x?.categoryVisiblity !== 'Items are visible, but item prices are NOT visible to your customer' && <div className='valuetd' style={{ fontWeight: '500' }}>
                                                        ${item?.price ? parseFloat(item.price)?.toFixed(2) : '0'}
                                                    </div>}
                                                </td>
                                                <td  >
                                                    {x?.categoryVisiblity !== 'Items are visible, but item prices are NOT visible to your customer' && <div className='valuetd' style={{ fontWeight: '500' }}>
                                                        ${item?.price && item?.quantity ? putCommaInvalue((item?.price * item?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(item?.price)?.toFixed(2))}
                                                    </div>}
                                                </td>


                                            </tr>)
                                    }

                                </>
                                )
                            })}
                        </Table>}
                        {quotedata?.items?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')?.length > 0 && <Table>

                            <tr style={{ backgroundColor: '#fff', background: '#DEDEDE' }}>
                                {/* <td>Name</td>
                        <td></td> */}
                                <td style={{ fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Service items"}</td>


                                {/* <td></td> */}
                                {/* <td></td>
                            <td></td> */}
                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Quantity"}</td>



                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Unit price"}</td>




                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Amount"}</td>

                            </tr>

                            {quotedata?.items?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')?.map((x, index) => {
                                return (x?.optional ? null : <>
                                    <tr style={{ backgroundColor: '#fff' }}>
                                        {/* <td>{x?.name ? x?.name : '-'}</td>
                                <td></td> */}
                                        <td><div>
                                            {x?.picUrl && <ItemImg src={x?.picUrl} />}
                                            <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {x?.name ? `${x?.name}` : '-'}</p>
                                            {x?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{x?.description ? `${x?.description}` : '-'}</p>}
                                        </div>
                                        </td>


                                        {/* <td></td> */}
                                        {/* <td></td>
                                    <td></td> */}
                                        <td style={{ maxWidth: '100px' }}>{x?.quantity ? putCommaInvalue(x?.quantity) : '1'}</td>


                                        <td >${x?.price ? parseFloat(x.price)?.toFixed(2) : '0'}</td>




                                        <td>${x?.price && x?.quantity ? putCommaInvalue((x?.price * x?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(x?.price)?.toFixed(2))}</td>

                                    </tr>

                                </>
                                )
                            })}




                        </Table>}
                        {quotedata?.items?.filter((item) => item?.type === 'Material' && item?.type !== 'Bundle')?.length > 0 && <Table style={{ marginTop: '20px' }}>

                            <tr style={{ backgroundColor: '#fff', background: '#DEDEDE' }}>
                                {/* <td>Name</td>
<td></td> */}
                                <td style={{ fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Material items"}</td>


                                {/* <td></td> */}
                                {/* <td></td>
    <td></td> */}
                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Quantity"}</td>



                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Unit price"}</td>




                                <td style={{ width: '130px', fontSize: '12px', fontWeight: '500' }} className='tdtitlw'>{"Amount"}</td>

                            </tr>

                            {quotedata?.items?.filter((item) => item?.type === 'Material' && item?.type !== 'Bundle')?.map((x, index) => {
                                return (x?.optional ? null : <>
                                    <tr style={{ backgroundColor: '#fff' }}>
                                        {/* <td>{x?.name ? x?.name : '-'}</td>
        <td></td> */}
                                        <td><div>
                                            {x?.picUrl && <ItemImg src={x?.picUrl} />}
                                            <p style={{ marginBottom: 0, marginTop: 0, fontWeight: '600' }}> {x?.name ? `${x?.name}` : '-'}</p>
                                            {x?.description && <p style={{ marginTop: 4, color: 'gray', whiteSpace: 'pre-line', marginBottom: 0, maxWidth: '300px' }}>{x?.description ? `${x?.description}` : '-'}</p>}
                                        </div>
                                        </td>


                                        {/* <td></td> */}
                                        {/* <td></td>
            <td></td> */}
                                        <td style={{ maxWidth: '100px' }}>{x?.quantity ? putCommaInvalue(x?.quantity) : '1'}</td>


                                        <td >${x?.price ? parseFloat(x.price)?.toFixed(2) : '0'}</td>




                                        <td>${x?.price && x?.quantity ? putCommaInvalue((x?.price * x?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(x?.price)?.toFixed(2))}</td>

                                    </tr>

                                </>
                                )
                            })}




                        </Table>}
                        <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'flex-end', width: '100%', fontSize: '14px', fontWeight: '400' }}>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginRight: '5px' }}>
                                <p>Subtotal</p>
                                <p>{`$${putCommaInvalue(flattenItems(quotedata?.items)?.reduce(function (accumulator, currentValue) {
                                    if (!currentValue?.optional) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                    }
                                    return accumulator
                                }, 0)?.toFixed(2))}`}</p>
                            </div>
                        </div>
                        {taxval?.map((item) => (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: '14px', fontWeight: '400' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginRight: '5px' }}>
                                    <div>
                                        <p style={{ marginTop: '5px' }}>{item?.taxName} ({item?.taxPercentage}%)</p>

                                    </div>
                                    <p style={{ marginTop: '5px' }}>{`$${putCommaInvalue(parseFloat(item?.taxAmount)?.toFixed(2))}`}</p>
                                </div>
                            </div>)
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: '14px', fontWeight: '400' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginRight: '5px' }}>
                                <p style={{ marginTop: '5px' }}>Discount</p>
                                <p style={{ marginTop: '5px' }}>${applyDiscount()}</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: '14px', fontWeight: '500' }}>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginRight: '5px' }}>
                                <p style={{ marginTop: '5px' }}>Total</p>
                                <p style={{ marginTop: '5px' }}>{`$${putCommaInvalue((flattenItems(quotedata?.items)?.reduce(function (accumulator, currentValue) {
                                    if (!currentValue?.optional) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                    }
                                    return accumulator;
                                }, 0) - applyDiscount() + calculateTotalTaxAmount(flattenItems(quotedata?.items), applyDiscount()))?.toFixed(2))}`}</p>
                            </div>

                        </div>
                        {quotedata?.deposit && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%', fontSize: '14px', fontWeight: '500' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginRight: '5px' }}>
                                <p style={{ marginTop: '5px' }}>Deposit</p>
                                <p style={{ marginTop: '5px' }}>{`$${quotedata?.deposit}`}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginRight: '5px' }}>
                                <p style={{ marginTop: '5px' }}>New balance</p>
                                <p style={{ marginTop: '5px' }}>{`$${putCommaInvalue(((flattenItems(quotedata?.items)?.reduce(function (accumulator, currentValue) {
                                    if (!currentValue?.optional) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                    }
                                    return accumulator;
                                }, 0) - applyDiscount() + calculateTotalTaxAmount(flattenItems(quotedata?.items), applyDiscount())) - quotedata?.deposit)?.toFixed(2))}`}</p>
                            </div>
                        </div>

                        }
                        {quotedata?.attachment?.length > 0 && <BottomMessageContainer>
                            <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Attachments / Photos</h3>
                            <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '10px' }}>
                                    {quotedata?.attachment?.map((item) =>
                                        <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                            <PhotosStyle src={item} />

                                        </div>
                                    )}


                                </div>

                            </div>
                        </BottomMessageContainer>}
                        {userData?.termCondition?.[0]?.invoice && <BottomMessageContainer>
                            <h3 style={{ textAlign: 'start', marginBottom: '0px' }}>Terms & Conditions</h3>

                            <BottomMessageText>{userData?.termCondition?.[0]?.invoice}</BottomMessageText>
                        </BottomMessageContainer>}


                    </div>



                </div>
            </MainContainer>
        </Body>
    );
};

export default InvoiceReport;
const Body = styled.div`
display: flex;
  justify-content: center;

  background:#FAFAFA;
  width:100%;
`
const MainContainer = styled.div`
overflow:auto;
margin-bottom:50px;
width:230mm;
height:297mm;
background:#fff;
position:relative;


`

const Container = styled.div`
display:flex;
 gap:2rem;
 margin-bottom:25px;
 .detailCont{
     width:40%;
     text-align:start;
 }
`
const OutProfile = styled.div`
background:${palette.newBlue};
color:white;
width:100px;
height:100px;
display:flex;
justify-content:center;
align-self:start;
margin-right:-20px;
align-items:center;
font-size:28px;
font-weight:bold;


`
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: start;
  font-size: 11px;
  color: black;

  .divider {
    font-weight: 300;
    line-height: 235%;
  }

  tr:nth-child(odd) {
    background-color: #FAFAFA;
  }

  th:first-child {
    border-radius: 10px 0 0 0px;
  }

  th:last-child {
    border-radius: 0 10px 0px 0;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid #ddd;
    font-style: normal;
    font-weight: 500;
    vertical-align: top;
    
  }

  .tdtitle {
    font-size: 14px;
    font-weight: 600;
    color: gray;
  }

  .totaltd {
    font-weight: 600;
  }
`;

const ProfilePic = styled.img`
background:#fff;
color:white;
width:100px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:-20px;
font-weight:bold;


`
const BottomMessageContainer = styled.div`
margin-top:30px;
text-align:start;
@media (min-width: 260px) and (max-width: 900px){
    zoom:60%;
}
`;

const BottomMessageText = styled.p`
    font-size: 14px;
    color: ${palette.black};
    line-height:1.2;
    margin-bottom:40px;
    font-weight:500;
    white-space: pre-line;

`;
const ItemImg = styled.img`
background:#fff;
color:white;
width:50px;
margin-bottom:5px;
height:50px;
display:flex;
justify-content:center;
align-items:center;
font-weight:bold;
`
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:15px;
margin-bottom:10px;
width: 120px;
height: 120px;

`
